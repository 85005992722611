export const courseFilter = `{operator:"=", name:"course", value:"course"}`;
export const facets = `facets:{field:"field_ecommerce_groups", limit: 0, operator:"=", min_count:1, missing:false}`;
export const fulltextFields = `
  "name",
  "name_fulltext",
  "field_search_synonyms",
  "field_variant",
  "field_course_conditions",
  "field_course_content",
  "field_course_target_audience"
`;
export const queriedFields = `
    name,
    id,
    url,
    field_participants_count,
    field_max_participants,
    field_min_participants,
    field_ecommerce_groups,
    field_course_location,
    field_schedule_string,
    field_course_start_end_date,
    field_course_display_location,
    field_course_filter_degree,
    field_cost,
    field_variant,
    field_startdate_on_request,
    location_names,
    relevance
`;
export const queryMeta = `
    result_count
    facets {
        name
        values {
          count
          filter
        }
    }
`;
