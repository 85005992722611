import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectFilterActive,
  makeSelectStructureFilterComplex,
  makeSelectStructureFilterContent,
} from '../selectors';
import { makeSelectPreSetStructure } from '../../App/selectors';
import {
  setFilterActive,
  setStructureFilterComplex,
  setStructureFilterDataArray,
  setStructureFilterId,
} from '../actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import initialStateConstants from '../initialStateConstants';

export const StructurePreFilter = ({
  structureItems,
  preSetStructure,
  structureComplex,
  onSetStructureFilterComplex,
  onSetStructureFilterId,
  onSetStructureFilterDataArray,
}) => {
  const appHistory = useHistory();
  const { search } = useLocation();
  const [queryParams, setQueryParams] = useState(queryString.parse(search)?.filter ?? '');

  /**
   * @implements useEffect()
   *
   * Checks if filter and pre-set filtering should be run once (new) filterable items
   * become available.
   */
  useEffect(() => {
    if (preSetStructure.length > 0 || queryParams.length > 0) {
      preFilter(queryParams);
    }
  }, [structureItems]);

  /**
   * @implements useEffect()
   *
   * Checks if a query parameter for this filter has changed and
   * kicks-off updates.
   */
  useEffect(() => {
    let newQueryString = queryString.parse(search)?.filter ?? '';
    if (newQueryString === queryParams) {
      return null;
    }
    setQueryParams(newQueryString);
    if (appHistory.action === 'POP') {
      preFilter(newQueryString);
    }
  }, [search]);

  const preFilter = (newQueryString) => {
    if (Object.keys(structureItems).length === 0) {
      return null;
    }
    let structureFilter = [];
    if (preSetStructure.length > 0 && structureComplex.length === 0) {
      structureFilter = preSetStructure;
    }
    if (newQueryString.length > 0) {
      structureFilter = newQueryString.split('_');
    }
    if (structureFilter?.length > 0) {
      onSetStructureFilterComplex(structureFilter);
      onSetStructureFilterId(structureFilter[0]);
      onSetStructureFilterDataArray(parsePreSetFilterNames(structureFilter));
    } else {
      resetFilter();
    }
  };

  const parsePreSetFilterNames = (structureFilter) => {
    let counter = structureFilter.length - 1;
    let preSetFilterNames = [];
    let currentObject = structureItems[structureFilter[counter]];
    while (counter >= 0) {
      preSetFilterNames.unshift(currentObject.name);
      if (currentObject.children === null) {
        return preSetFilterNames;
      }
      counter -= 1;
      currentObject = currentObject.children[structureFilter[counter]];
    }

    return preSetFilterNames;
  };

  const resetFilter = () => {
    onSetStructureFilterComplex(initialStateConstants.structureFilterComplex);
    onSetStructureFilterId(initialStateConstants.structureFilterId);
    onSetStructureFilterDataArray(initialStateConstants.structureFilterDataArray);
  };

  return <></>;
};

StructurePreFilter.propTypes = {
  structureItems: PropTypes.object,
  onSetStructureFilterId: PropTypes.func,
  onSetStructureFilterComplex: PropTypes.func,
  onSetStructureFilterDataArray: PropTypes.func,
  preSetStructure: PropTypes.array,
  structureComplex: PropTypes.array,
  filterActive: PropTypes.number,
  onSetFilterActive: PropTypes.func,
};

StructurePreFilter.defaultProps = {
  structureItems: {},
  onSetStructureFilterId: () => {},
  onSetStructureFilterComplex: () => {},
  onSetStructureFilterDataArray: () => {},
  preSetStructure: [],
  structureComplex: [],
  filterActive: 0,
  onSetFilterActive: () => {},
};

const mapStateToProps = createStructuredSelector({
  structureItems: makeSelectStructureFilterContent(),
  preSetStructure: makeSelectPreSetStructure(),
  structureComplex: makeSelectStructureFilterComplex(),
  filterActive: makeSelectFilterActive(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onSetStructureFilterId: (value) => dispatch(setStructureFilterId(value)),
    onSetStructureFilterComplex: (value) => dispatch(setStructureFilterComplex(value)),
    onSetStructureFilterDataArray: (value) => dispatch(setStructureFilterDataArray(value)),
    onSetFilterActive: (value) => dispatch(setFilterActive(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(StructurePreFilter);
