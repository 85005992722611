import React, { memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { setFilterMenuStatus, setLocationFilterDataArray, setLocationFilterId } from '../actions';
import { loadCourses } from '../../App/actions';
import CollapsedList from '../../../utils/Components/CollapsedList';
import FilterCollapse from './FilterCollapse';
import FilterOverlayBlock from '../../../utils/Components/FilterOverlayBlock';
import { FilterHeaderBlock } from '../FilterOverlay/FilterHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import LocalReset from '../../../utils/Components/LocalReset';
import CurrentFilterResetBlock from '../../../components/CurrentFilterResetBlock';
import { useInjectReducer } from '../../../utils/injectReducer';
import reducer from './reducer';
import { initialState } from '../reducer';
import { makeSelectLocationFilterMenuStatus } from './selectors';
import { toggleLocationFilterMenu } from './actions';
import Items from './Items';
import ShowResultsButton from '../../../utils/Components/ShowResultsButton';
import { makeSelectPreSetLocation } from '../../App/selectors';
import FilterListWrapper from '../../../utils/Components/FilterListWrapper';

const key = 'location';

export function SearchLocations({
  locationFilterMenuStatus,
  onToggleLocationFilterMenu,
  onSetLocationFilterDataArray,
  onSetLocationFilterId,
  onSetFilterMenuStatus,
}) {
  useInjectReducer({ key, reducer });

  // useEffect(() => {
  //   if (preSetLocation.length > 0 && locationDataFilterArray.length === 0) {
  //     onSetLocationFilterId(preSetLocation);
  //   }
  // }, [preSetLocation]);
  //
  // useEffect(() => {
  //   if (preSetLocation.length > 0 && locationDataFilterArray.length === 0) {
  //     let locations = [];
  //     preSetLocation.map((item) => locations.push(locationItems[findKey(locationItems, item)][item].name));
  //     onSetLocationFilterDataArray(locations);
  //   }
  // }, [locationItems]);
  //
  const resetFilter = () => {
    onSetLocationFilterId(initialState.locationFilterId);
    onSetLocationFilterDataArray(initialState.locationFilterDataArray);
  };

  const handleResultsClick = () => {
    onToggleLocationFilterMenu(false);
    onSetFilterMenuStatus(false);
  };

  const CurrentFilterReset = () => (
    <LocalReset onClick={() => resetFilter()}>
      <CurrentFilterResetBlock />
    </LocalReset>
  );

  /**
   * Main return function.
   */
  return (
    <CollapsedList className={locationFilterMenuStatus ? 'open' : ''}>
      <FilterCollapse />
      <FilterOverlayBlock className={'item-list'}>
        <FilterHeaderBlock>
          <span>STANDORT</span>
          <FontAwesomeIcon icon={faArrowLeft} onClick={() => onToggleLocationFilterMenu(false)} />
        </FilterHeaderBlock>
        <CurrentFilterReset />
        <FilterListWrapper>
          <Items />
        </FilterListWrapper>
        <ShowResultsButton onClick={() => handleResultsClick()}>Resultate anzeigen</ShowResultsButton>
      </FilterOverlayBlock>
    </CollapsedList>
  );
}

SearchLocations.propTypes = {
  locationFilterMenuStatus: PropTypes.bool,
  onToggleLocationFilterMenu: PropTypes.func,
  onSetLocationFilterDataArray: PropTypes.func,
  onSetLocationFilterId: PropTypes.func,
  onSetFilterMenuStatus: PropTypes.func,
};

SearchLocations.defaultProps = {
  locationFilterMenuStatus: false,
  onToggleLocationFilterMenu: () => {},
  onSetLocationFilterDataArray: () => {},
  onSetLocationFilterId: () => {},
  onSetFilterMenuStatus: () => {},
};

const mapStateToProps = createStructuredSelector({
  locationFilterMenuStatus: makeSelectLocationFilterMenuStatus(),
  preSetLocation: makeSelectPreSetLocation(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onLoadCourses: () => dispatch(loadCourses()),
    onToggleLocationFilterMenu: (value) => dispatch(toggleLocationFilterMenu(value)),
    onSetLocationFilterDataArray: (value) => dispatch(setLocationFilterDataArray(value)),
    onSetLocationFilterId: (value) => dispatch(setLocationFilterId(value)),
    onSetFilterMenuStatus: (value) => dispatch(setFilterMenuStatus(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(SearchLocations);
