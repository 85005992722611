import { call, put, takeLatest } from 'redux-saga/effects';
import { select } from '@redux-saga/core/effects';
import request from '../../utils/request';
import { LOAD_COURSES } from './constants';
import { SEARCH_PATH } from '../../utils/constants';
import { orderBy } from 'lodash';
import { courseFilter, facets, queriedFields, queryMeta, fulltextFields } from '../../utils/query/queryBasic';
import { setAppIsLoading, setCourses, setFacetInformation, setTotalItemCount } from './actions';
import {
  makeSelectDaysFilterId,
  makeSelectLocationFilterId,
  makeSelectDegreeFilterId,
  makeSelectSearchString,
  makeSelectStructureFilterId,
  makeSelectTimeFilterId,
} from '../Search/selectors';

const graphqlQuery = `
  query {
    searchAPISearch(
      index_id:"solr_index",
      fulltext: {keys: SEARCH_STRING, fields: [${fulltextFields}]},
      ${facets},
      range:{offset:0,limit:500},
      sort: {field: "field_course_start_end_date", value: "asc"},
      SEARCH_FILTER,
    ) {
      ${queryMeta}
      documents{
        ... on SolrIndexDoc {
          ${queriedFields}
        }
      }
    }
  }
`;

const setSearchString = (query, stringSearch) => {
  if (stringSearch.length > 0) {
    let searchText = stringSearch;
    const searchArray = [];
    const exactMatches = searchText.match(/"([^"]*)"/);

    if (exactMatches) {
      searchText = searchText.replace(exactMatches[0], '');
      searchArray.push(exactMatches[1]);
    }
    searchText
      .trim()
      .split(' ')
      .map((item) => (item.length > 0 ? searchArray.push(item) : null));

    return query.replace('SEARCH_STRING', JSON.stringify(searchArray));
  }
  return query.replace('SEARCH_STRING', '""');
};

const setSearchFilters = (structureItems, locationItems, dayItems, timeItems, degreeItems) => {
  const conditionGroups = [];

  conditionGroups.push(`
    {
      conjunction: OR,
      conditions: [
        ${courseFilter}
      ]
    }
  `);

  if (structureItems.length > 0) {
    const search = `
        {
          conjunction: OR,
          conditions: [
            {operator:"=", name:"field_ecommerce_groups", value:"${structureItems}"}
          ]
        }
    `;
    conditionGroups.push(search);
  }
  if (locationItems.length > 0) {
    const search = `
        {
          conjunction: OR,
          conditions: [
            ${locationItems.map((item) => `{operator:"=", name:"field_course_location", value:"${item}"}`)}
          ]
        }
    `;
    conditionGroups.push(search);
  }
  if (degreeItems.length > 0) {
    const search = `
        {
          conjunction: OR,
          conditions: [
            ${degreeItems.map((item) => `{operator:"=", name:"field_course_filter_degree", value:"${item}"}`)}
          ]
        }
    `;
    conditionGroups.push(search);
  }
  if (dayItems.length > 0) {
    const search = `
        {
          conjunction: AND,
          conditions: [
            ${dayItems.map((item) => `{operator:"=", name:"field_days", value:"${item}"}`)}
          ]
        }
    `;
    conditionGroups.push(search);
  }
  if (timeItems.length > 0) {
    const search = `
        {
          conjunction: AND,
          conditions: [
            ${timeItems.map((item) => `{operator:"=", name:"field_times", value:"${item}"}`)}
          ]
        }
    `;
    conditionGroups.push(search);
  }

  return `
    condition_group: {
      conjunction: AND,
      groups: [${conditionGroups}]
    }
  `;
};

const getSearchFilters = (query, structureItems, locationItems, dayItems, timeItems, degreeItems) => {
  const filterItems = setSearchFilters(structureItems, locationItems, dayItems, timeItems, degreeItems);
  if (filterItems.length === 0) {
    return query.replace('SEARCH_FILTER', `[${courseFilter}]`);
  }
  return query.replace('SEARCH_FILTER', filterItems);
};

export function* apiLoadCourses() {
  yield put(setAppIsLoading(true));
  const stringSearch = yield select(makeSelectSearchString());
  const structureItems = yield select(makeSelectStructureFilterId());
  const locationItems = yield select(makeSelectLocationFilterId());
  const degreeItems = yield select(makeSelectDegreeFilterId());
  const dayItems = yield select(makeSelectDaysFilterId());
  const timeItems = yield select(makeSelectTimeFilterId());

  let searchQuery = setSearchString(graphqlQuery, stringSearch);
  searchQuery = getSearchFilters(searchQuery, structureItems, locationItems, dayItems, timeItems, degreeItems);
  const options = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      query: searchQuery,
    }),
  };
  try {
    const courseRequest = yield call(request, window.location.origin + SEARCH_PATH, options);

    let courses = courseRequest.data.searchAPISearch.documents;
    if (stringSearch.length > 0) {
      courses = orderBy(courses, ['relevance'], ['desc']);
    }
    yield put(setCourses(courses));
    yield put(setTotalItemCount(courseRequest.data.searchAPISearch.result_count));
    yield put(setFacetInformation(courseRequest.data.searchAPISearch.facets));
  } catch (err) {
    console.error(err);
  } finally {
    yield put(setAppIsLoading(false));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* loadDefault() {
  yield takeLatest(LOAD_COURSES, apiLoadCourses);
}
