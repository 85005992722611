import React, { memo, useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import {
  makeSelectFilterMenuStatus,
  makeSelectDegreeFilterDataArray,
  makeSelectDegreeFilterId,
} from '../selectors';
import { toggleDegreeFilterMenu } from './actions';
import ParentElement from '../../../components/FilterElement/ParentElement';

export const FilterCollapse = ({ onToggleDegreeFilterMenu, degreeFilterMenuStatus, degreeFilterDataArray }) => {
  const [bubbleString, setBubbleString] = useState('');

  useEffect(() => {
    let newBubbleString = '';
    if (degreeFilterDataArray.length > 0) {
      newBubbleString = degreeFilterDataArray.join(', ');
    }
    if (bubbleString !== newBubbleString) {
      setBubbleString(newBubbleString);
    }
  }, [degreeFilterDataArray]);

  /**
   * Main return function.
   */
  return (
    <div onClick={() => onToggleDegreeFilterMenu(degreeFilterMenuStatus)}>
      <ParentElement title={'Abschluss'} bubble={bubbleString} status={degreeFilterMenuStatus} />
    </div>
  );
};

FilterCollapse.propTypes = {
  degreeFilterDataArray: PropTypes.array,
  onToggleDegreeFilterMenu: PropTypes.func,
  degreeFilterMenuStatus: PropTypes.bool,
};

FilterCollapse.defaultProps = {
  degreeFilterDataArray: [],
  onToggleDegreeFilterMenu: () => {},
  degreeFilterMenuStatus: true,
};

const mapStateToProps = createStructuredSelector({
  degreeFilterId: makeSelectDegreeFilterId(),
  degreeFilterMenuStatus: makeSelectFilterMenuStatus(),
  degreeFilterDataArray: makeSelectDegreeFilterDataArray(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onToggleDegreeFilterMenu: (value) => dispatch(toggleDegreeFilterMenu(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterCollapse);
