import React, { memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { makeSelectFilterMenuStatus, makeSelectDaysFilterDataArray, makeSelectDaysFilterId } from '../selectors';
import { toggleDaysFilterMenu } from './actions';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PillYellow, { PillText } from '../../../utils/Components/PillYellow';
import { setDaysFilterDataArray, setDaysFilterId } from '../actions';

const key = 'days';

export const FilterPills = ({ daysFilterId, daysFilterDataArray, onSetDaysFilterId, onSetDaysFilterDataArray }) => {
  if (daysFilterDataArray.length === 0) {
    return <></>;
  }

  const handlePillClick = (index) => {
    onSetDaysFilterId([...daysFilterId.slice(0, index), ...daysFilterId.slice(index + 1)]);
    onSetDaysFilterDataArray([...daysFilterDataArray.slice(0, index), ...daysFilterDataArray.slice(index + 1)]);
  };

  /**
   * Main return function.
   */
  return daysFilterDataArray.map((item, index) => (
    <PillYellow key={`${key}_${item}`} onClick={() => handlePillClick(index)}>
      <PillText>{item}</PillText>
      <FontAwesomeIcon icon={faTimes} />
    </PillYellow>
  ));
};

FilterPills.propTypes = {
  daysFilterId: PropTypes.array,
  onToggleDaysFilterMenu: PropTypes.func,
  daysFilterMenuStatus: PropTypes.bool,
};

FilterPills.defaultProps = {
  daysFilterDataArray: [],
  onToggleDaysFilterMenu: () => {},
  daysFilterMenuStatus: true,
};

const mapStateToProps = createStructuredSelector({
  daysFilterId: makeSelectDaysFilterId(),
  daysFilterMenuStatus: makeSelectFilterMenuStatus(),
  daysFilterDataArray: makeSelectDaysFilterDataArray(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onToggleDaysFilterMenu: (value) => dispatch(toggleDaysFilterMenu(value)),
    onSetDaysFilterId: (value) => dispatch(setDaysFilterId(value)),
    onSetDaysFilterDataArray: (value) => dispatch(setDaysFilterDataArray(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterPills);
