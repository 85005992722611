import produce from 'immer';

import {
  LOAD_COURSES,
  SET_APP_IS_LOADING,
  SET_APP_IS_MOBILE,
  SET_COURSES,
  SET_FACETS,
  SET_PRESET_LOCATION,
  SET_PRESET_DEGREE,
  SET_PRESET_STRUCTURE,
  SET_TOTAL,
} from './constants';

export const initialState = {
  courses: [],
  totalResults: 0,
  facets: [],
  preSetLocation: [],
  preSetDegree: [],
  preSetStructure: [],
  appIsMobile: false,
  appIsLoading: true,
};

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_COURSES:
        draft.courses = action.courses;
        break;
      case SET_APP_IS_MOBILE:
        draft.appIsMobile = action.value;
        break;
      case SET_TOTAL:
        draft.totalResults = action.value;
        break;
      case SET_FACETS:
        draft.facets = action.value;
        break;
      case SET_PRESET_LOCATION:
        draft.preSetLocation = action.value;
        break;
      case SET_PRESET_DEGREE:
        draft.preSetDegree = action.value;
        break;
      case SET_PRESET_STRUCTURE:
        draft.preSetStructure = action.value;
        break;
      case SET_APP_IS_LOADING:
        draft.appIsLoading = action.value;
        break;
      case LOAD_COURSES:
      default:
        break;
    }
  });

export default appReducer;
