import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Colors from '../../utils/Colors';
import Transitions from '../../utils/Transitions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/pro-solid-svg-icons';

const FilterElementRow = styled.div`
  padding: 14px 16px 14px 5px;
  display: flex;
  position: relative;
  width: 100%;
  cursor: pointer;
  justify-content: flex-start;
  align-items: center;
  transition: ${Transitions.all};
  &:hover {
    background-color: ${Colors.grey};
  }

  &:active {
    background-color: ${Colors.greyAction};
    .title {
      transition: ${Transitions.all};
      transform: scale3d(0.97, 0.97, 1);
    }
  }
`;

const Title = styled.div`
  display: block;
  text-align: left;
`;

const SignetBox = styled.div`
  display: inline-flex;
  position: relative;
  border: 1px solid ${Colors.textBlack};
  height: 20px;
  width: 20px;
  margin-right: 10px;
  justify-content: center;
  align-items: center;

  &.active {
    background-color: ${Colors.yellow};
  }

  .svg-inline--fa {
    top: 5px;
    font-size: 12px;
  }
`;

export const FilterElementRadio = ({ title, status }) => {
  const Signet = () => {
    if (!status) {
      return <SignetBox />;
    }
    return (
      <SignetBox className={'active'}>
        <FontAwesomeIcon icon={faCheck} />
      </SignetBox>
    );
  };

  return (
    <FilterElementRow>
      <Signet />
      <Title className={'title'}>{title}</Title>
    </FilterElementRow>
  );
};

FilterElementRadio.propTypes = {
  title: PropTypes.string,
  bubble: PropTypes.string,
  status: PropTypes.bool,
  position: PropTypes.number,
};

FilterElementRadio.defaultProps = {
  title: '',
  bubble: '',
  status: false,
  position: 0,
};

export default FilterElementRadio;
