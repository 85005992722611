import React, { memo, useState, useEffect } from 'react';
import { Collapse } from '@nkbt/react-collapse';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { makeSelectStructureFilterComplex, makeSelectStructureFilterDataArray } from '../selectors';
import { loadCourses } from '../../App/actions';
import { setStructureFilterComplex, setStructureFilterDataArray, setStructureFilterId } from '../actions';
// eslint-disable-next-line import/no-self-import
import SubGroup from './ItemGroup';
import FilterElement from '../../../components/FilterElement';
import { makeSelectStructureFilterMenuStatus } from './selectors';

export const ItemGroup = ({
  item,
  structureComplex,
  structureFilterDataArray,
  onSetStructureFilterComplex,
  onSetStructureFilterDataArray,
  structureFilterMenuStatus,
}) => {
  const [itemIsOpen, setItemIsOpen] = useState(false);
  const [position, setPosition] = useState(0);

  useEffect(() => {
    // const filterDepth = ;
    const testItemOpenStatus = structureComplex.indexOf(item.id) >= 0;
    if (testItemOpenStatus !== itemIsOpen) {
      setItemIsOpen(!itemIsOpen);
    }
    if (testItemOpenStatus) {
      setPosition(structureComplex.indexOf(item.id));
    }
  }, [structureComplex]);

  useEffect(() => {
    if (itemIsOpen && !structureFilterMenuStatus) {
      setItemIsOpen(false);
    }
  }, [structureFilterMenuStatus]);

  const itemClickHandler = () => {
    const ownIndex = structureComplex.indexOf(item.id);
    let currentIds = [];
    let currentEntries = [];
    let parentIndex = structureComplex.indexOf(item.parent);
    const entriesLength = structureComplex.length;

    // Catching direct children
    if (entriesLength === 0 || parentIndex === 0) {
      onSetStructureFilterComplex([item.id, ...structureComplex]);
      onSetStructureFilterDataArray([item.name, ...structureFilterDataArray]);
      return null;
    }
    if (ownIndex === 0) {
      currentIds = structureComplex.slice(1, entriesLength);
      currentEntries = structureFilterDataArray.slice(1, entriesLength);
      onSetStructureFilterComplex(currentIds);
      onSetStructureFilterDataArray(currentEntries);
    } else {
      parentIndex = parentIndex > 0 ? parentIndex : entriesLength;
      currentIds = structureComplex.slice(parentIndex, entriesLength);
      currentEntries = structureFilterDataArray.slice(parentIndex, entriesLength);
      onSetStructureFilterComplex([item.id, ...currentIds]);
      onSetStructureFilterDataArray([item.name, ...currentEntries]);
    }
  };

  return (
    <>
      <div onClick={() => itemClickHandler()}>
        <FilterElement title={item.name} status={itemIsOpen} bubble={''} position={position} />
      </div>
      <Collapse isOpened={itemIsOpen}>
        {Object.entries(item.children).map((subItem) => (
          <SubGroup key={subItem[1].id} item={subItem[1]} />
        ))}
      </Collapse>
    </>
  );
};

ItemGroup.propTypes = {
  item: PropTypes.object,
  structureComplex: PropTypes.array,
  onSetStructureFilterComplex: PropTypes.func,
  structureFilterDataArray: PropTypes.array,
  onSetStructureFilterDataArray: PropTypes.func,
  structureFilterMenuStatus: PropTypes.bool,
};

ItemGroup.defaultProps = {
  item: {},
  structureComplex: [],
  onSetStructureFilterComplex: () => {},
  structureFilterDataArray: [],
  onSetStructureFilterDataArray: () => {},
  structureFilterMenuStatus: false,
};

const mapStateToProps = createStructuredSelector({
  structureComplex: makeSelectStructureFilterComplex(),
  structureFilterDataArray: makeSelectStructureFilterDataArray(),
  structureFilterMenuStatus: makeSelectStructureFilterMenuStatus(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onLoadCourses: () => dispatch(loadCourses()),
    onSetStructureFilterComplex: (value) => dispatch(setStructureFilterComplex(value)),
    onSetStructureFilterId: (value) => dispatch(setStructureFilterId(value)),
    onSetStructureFilterDataArray: (value) => dispatch(setStructureFilterDataArray(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(ItemGroup);
