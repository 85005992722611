import React from 'react';
import { faCheckCircle, faHourglassStart } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Colors from '../../utils/Colors';

export const SpanGrey = styled.span`
  font-size: 14px;
  color: ${Colors.greyDark};
`;

const StatusSpan = styled.span`
  margin-left: 8px;
`;

export const CourseStatus = ({ current, max, min }) => {
  if (current >= max) {
    return (
      <SpanGrey>
        <FontAwesomeIcon icon={faHourglassStart} color={Colors.textBlack} />
        <StatusSpan>ausgebucht, Warteliste</StatusSpan>
      </SpanGrey>
    );
  } else if (current >= min) {
    return (
      <SpanGrey>
        <FontAwesomeIcon icon={faCheckCircle} color={Colors.green} />
        <StatusSpan>Garantierte Durchführung</StatusSpan>
      </SpanGrey>
    );
  }
  return (
    <SpanGrey>
      <span>Anmeldung möglich</span>
    </SpanGrey>
  );
};

CourseStatus.propTypes = {
  current: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
};

CourseStatus.defaultProps = {
  current: 0,
  max: 0,
  min: 0,
};

export default CourseStatus;
