import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { useInjectSaga } from '../../utils/injectSaga';
import saga from './saga';
import StringSearch from './StringSearch';
import { loadAllFilterItems } from './actions';
import StructureFilter from './FilterActions/StructureFilter';
import Colors from '../../utils/Colors';
import FilterMenuButton from './FilterMenuButton';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import FilterOverlay from './FilterOverlay';
import FilterResetMobile from './FilterReset/FilterResetMobile';
import { APP_MAX_TABLET_WIDTH, MOBILE_PADDING_LR } from '../../utils/constants';

const key = 'search';

const SearchBlock = styled.div`
  background-color: ${Colors.grey};
  padding: 18px 0;
  display: block;
  @media (min-width: ${APP_MAX_TABLET_WIDTH}px) {
    padding: 18px ${MOBILE_PADDING_LR};
  }
`;
const SearchInnerBlock = styled.div`
  max-width: 100%;
  margin: 0 auto;
  @media (min-width: ${APP_MAX_TABLET_WIDTH}px) {
    max-width: 1136px;
  }
`;

export function Search({ onLoadAllFilterItems }) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  useEffect(() => {
    onLoadAllFilterItems();
  }, []);

  return (
    <SearchBlock>
      <SearchInnerBlock>
        <StringSearch />
        <FilterMenuButton />
        <FilterResetMobile />
      </SearchInnerBlock>
      <FilterOverlay />
      <StructureFilter />
    </SearchBlock>
  );
}

Search.propTypes = {
  onLoadAllFilterItems: PropTypes.func,
};

Search.defaultProps = {
  onLoadAllFilterItems: () => {},
};

const mapStateToProps = createStructuredSelector({});

export function mapDispatchToProps(dispatch) {
  return {
    onLoadAllFilterItems: () => dispatch(loadAllFilterItems()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Search);
