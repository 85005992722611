import React, { memo, useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { makeSelectFilterMenuStatus, makeSelectStructureFilterDataArray } from '../selectors';
import { toggleStructureFilterMenu } from './actions';
import ParentElement from '../../../components/FilterElement/ParentElement';
import { useInjectReducer } from '../../../utils/injectReducer';
import reducer from './reducer';

const key = 'filter';

export const FilterCollapse = ({
  structureFilterDataArray,
  onToggleStructureFilterMenu,
  structureFilterMenuStatus,
}) => {
  useInjectReducer({ key, reducer });
  const [bubbleString, setBubbleString] = useState('');

  useEffect(() => {
    let newBubbleString = '';
    if (structureFilterDataArray.length > 0) {
      newBubbleString = structureFilterDataArray[0];
    }
    if (bubbleString !== newBubbleString) {
      setBubbleString(newBubbleString);
    }
  }, [structureFilterDataArray]);

  /**
   * Main return function.
   */
  return (
    <div onClick={() => onToggleStructureFilterMenu(structureFilterMenuStatus)}>
      <ParentElement title={'Themenbereich'} bubble={bubbleString} status={structureFilterMenuStatus} />
    </div>
  );
};

FilterCollapse.propTypes = {
  structureFilterDataArray: PropTypes.array,
  onToggleStructureFilterMenu: PropTypes.func,
  structureFilterMenuStatus: PropTypes.bool,
};

FilterCollapse.defaultProps = {
  structureFilterDataArray: [],
  onToggleStructureFilterMenu: () => {},
  structureFilterMenuStatus: true,
};

const mapStateToProps = createStructuredSelector({
  structureFilterDataArray: makeSelectStructureFilterDataArray(),
  structureFilterMenuStatus: makeSelectFilterMenuStatus(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onToggleStructureFilterMenu: (value) => dispatch(toggleStructureFilterMenu(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterCollapse);
