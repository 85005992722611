import styled from 'styled-components';
import Transitions from '../Transitions';
import Colors from '../Colors';
import { APP_MAX_TABLET_WIDTH } from '../constants';

const buttonSmall = `
  justify-content: center;
  width: 60px;
  flex: 0 0 54px;

  span {
    padding: 3px 0 0 0;
    font-size: 12px;
  }

  .svg-inline--fa {
    padding: 0;
  }
`;
export const FilterSwitch = styled.button`
  height: 54px;
  width: 100%;
  justify-content: left;
  flex: 0 0 100%;
  display: flex;
  flex-wrap: wrap;
  transition: ${Transitions.all};
  border: 2px solid ${Colors.yellow};
  background-color: ${Colors.white};
  align-items: center;
  align-content: center;
  cursor: pointer;

  span {
    padding: 0 0 0 15px;
    font-size: 16px;
  }

  .svg-inline--fa {
    transition: ${Transitions.all};
    font-size: 16px;
    margin: 0 0 0 15px;
    color: ${Colors.greyDark};
    @media (min-width: ${APP_MAX_TABLET_WIDTH}px) {
      margin: 0;
      padding-left: 0;
      flex: 0 0 100%;
    }
  }

  &.on {
    ${buttonSmall}
  }

  &:hover {
    border: 2px solid ${Colors.yellowHover};

    .svg-inline--fa {
      color: ${Colors.yellowHover};
    }
    span {
      opacity: 0.5;
    }
  }

  &:active {
    background-color: ${Colors.greyAction};
    transform: scale3d(0.9, 0.9, 0.9);
  }

  @media (min-width: ${APP_MAX_TABLET_WIDTH}px) {
    ${buttonSmall}
  }
`;

export default FilterSwitch;
