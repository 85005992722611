import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectAppIsMobile } from '../../App/selectors';
import FilterMenuDesktop from './Desktop';
import FilterMenuMobile from './Mobile';

export const FilterMenuButton = ({ appIsMobile }) => {
  if (appIsMobile) {
    return <FilterMenuMobile />;
  }

  return <FilterMenuDesktop />;
};

FilterMenuButton.propTypes = {
  appIsMobile: PropTypes.bool,
};

FilterMenuButton.defaultProps = {
  appIsMobile: false,
};

const mapStateToProps = createStructuredSelector({
  appIsMobile: makeSelectAppIsMobile(),
});

export function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterMenuButton);
