import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  #course-app {
    font-size: 18px;
    p,
    span,
    li,
    td,
    input,
    button,
    label {
      font-family: sans-serif;
    }

    h1,
    h2,
    h3,
    h4,
    h2 span,
    a {
      font-family: sans-serif;
      font-weight: 500;
    }


    &.tstar-medium,
    &.tstar-medium div,
    &.tstar-medium p,
    &.tstar-medium span,
    &.tstar-medium li,
    &.tstar-medium td,
    &.tstar-medium input,
    &.tstar-medium button,
    &.tstar-medium label,
    &.tstar-medium .react-autosuggest__suggestions-container--open,
    &.tstar-medium h1,
    &.tstar-medium h2,
    &.tstar-medium h3,
    &.tstar-medium h4,
    &.tstar-medium h2 span,
    &.tstar-medium a {
      font-weight: 500;
      font-family: 'TStarMedium';
    }
  }

  .svg-inline--fa.fa-w-14,
  .svg-inline--fa.fa-w-10,
  .svg-inline--fa.fa-w-12 {
      width: unset;
  }

  .ReactCollapse--collapse {
    transition: height 250ms;
  }

  .ReactCollapse--collapse ul {
    margin: 0;
  }

  button:focus {
    outline: none;
  }
`;

export default GlobalStyle;
