import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setFilterMenuStatus } from '../actions';
import { makeSelectFilterMenuStatus } from '../selectors';

export const FilterHeaderBlock = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 22px;

  .svg-inline--fa {
    cursor: pointer;
    &:hover {
      opacity: 0.8;
    }
    &:active {
      transform: scale3d(0.9, 0.9, 1);
    }
  }
`;

export const FilterHeader = ({ onSetFilterMenuStatus }) => (
  <FilterHeaderBlock>
    <span>FILTER</span>
    <FontAwesomeIcon icon={faTimes} onClick={() => onSetFilterMenuStatus(false)} />
  </FilterHeaderBlock>
);

FilterHeader.propTypes = {
  onSetFilterMenuStatus: PropTypes.func,
};

FilterHeader.defaultProps = {
  onSetFilterMenuStatus: () => {},
};

const mapStateToProps = createStructuredSelector({
  filterMenuStatus: makeSelectFilterMenuStatus(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onSetFilterMenuStatus: (value) => dispatch(setFilterMenuStatus(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterHeader);
