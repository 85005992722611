import React, { memo, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectDegreeFilterContent,
  makeSelectDegreeFilterDataArray,
  makeSelectDegreeFilterId,
} from '../selectors';
import { makeSelectDegreeFilterMenuStatus } from './selectors';
import { loadCourses } from '../../App/actions';
import { setDegreeFilterDataArray, setDegreeFilterId } from '../actions';
import { toggleDegreeFilterMenu } from './actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import FilterElementRadio from '../../../components/FilterElement/FilterElementRadio';

export const Items = ({
  degreeItems,
  onSetDegreeFilterId,
  onLoadCourses,
  degreeFilterId,
  degreeFilterDataArray,
  onSetDegreeFilterDataArray,
}) => {
  useEffect(() => {
    onLoadCourses();
  }, [degreeFilterId]);

  const toggleFilterIds = (item) => {
    const itemIndex = degreeFilterId.indexOf(item.id);
    if (itemIndex >= 0) {
      onSetDegreeFilterId([...degreeFilterId.slice(0, itemIndex), ...degreeFilterId.slice(itemIndex + 1)]);
      onSetDegreeFilterDataArray([
        ...degreeFilterDataArray.slice(0, itemIndex),
        ...degreeFilterDataArray.slice(itemIndex + 1),
      ]);
    } else {
      onSetDegreeFilterId([item.id, ...degreeFilterId]);
      onSetDegreeFilterDataArray([item.name, ...degreeFilterDataArray]);
    }
  };

  if (degreeItems.length === 0) {
    return <></>;
  }

  return degreeItems.map((entry) => {
    const item = Object.entries(entry)[0];
    return (
      <div key={item[1].id} onClick={() => toggleFilterIds(item[1])}>
        <FilterElementRadio
          title={item[1].name}
          status={degreeFilterId.indexOf(item[1].id) >= 0}
          bubble={''}
          position={0}
        />
      </div>
    );
  });
};

Items.propTypes = {
  onLoadCourses: PropTypes.func,
  degreeItems: PropTypes.array,
  onSetDegreeFilterId: PropTypes.func,
  degreeFilterDataArray: PropTypes.array,
};

Items.defaultProps = {
  onLoadCourses: () => {},
  degreeItems: [],
  degreeFilterDataArray: [],
  onSetDegreeFilterId: () => {},
};

const mapStateToProps = createStructuredSelector({
  degreeFilterId: makeSelectDegreeFilterId(),
  degreeItems: makeSelectDegreeFilterContent(),
  degreeFilterMenuStatus: makeSelectDegreeFilterMenuStatus(),
  degreeFilterDataArray: makeSelectDegreeFilterDataArray(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onLoadCourses: () => dispatch(loadCourses()),
    onSetDegreeFilterId: (value) => dispatch(setDegreeFilterId(value)),
    onToggleDegreeFilterMenu: (value) => dispatch(toggleDegreeFilterMenu(value)),
    onSetDegreeFilterDataArray: (value) => dispatch(setDegreeFilterDataArray(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Items);
