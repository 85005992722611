import React, { memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import {
  makeSelectFilterMenuStatus,
  makeSelectLocationFilterDataArray,
  makeSelectLocationFilterId,
} from '../selectors';
import { toggleLocationFilterMenu } from './actions';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PillYellow, { PillText } from '../../../utils/Components/PillYellow';
import { setLocationFilterDataArray, setLocationFilterId } from '../actions';

const key = 'location';

export const FilterPills = ({
  locationFilterId,
  locationFilterDataArray,
  onSetLocationFilterId,
  onSetLocationFilterDataArray,
}) => {
  if (locationFilterDataArray.length === 0) {
    return <></>;
  }

  const handlePillClick = (index) => {
    onSetLocationFilterId([...locationFilterId.slice(0, index), ...locationFilterId.slice(index + 1)]);
    onSetLocationFilterDataArray([
      ...locationFilterDataArray.slice(0, index),
      ...locationFilterDataArray.slice(index + 1),
    ]);
  };

  /**
   * Main return function.
   */
  return locationFilterDataArray.map((item, index) => (
    <PillYellow key={`${key}_${item}`} onClick={() => handlePillClick(index)}>
      <PillText>{item}</PillText>
      <FontAwesomeIcon icon={faTimes} />
    </PillYellow>
  ));
};

FilterPills.propTypes = {
  locationFilterId: PropTypes.array,
  onToggleLocationFilterMenu: PropTypes.func,
  locationFilterMenuStatus: PropTypes.bool,
};

FilterPills.defaultProps = {
  locationFilterDataArray: [],
  onToggleLocationFilterMenu: () => {},
  locationFilterMenuStatus: true,
};

const mapStateToProps = createStructuredSelector({
  locationFilterId: makeSelectLocationFilterId(),
  locationFilterMenuStatus: makeSelectFilterMenuStatus(),
  locationFilterDataArray: makeSelectLocationFilterDataArray(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onToggleLocationFilterMenu: (value) => dispatch(toggleLocationFilterMenu(value)),
    onSetLocationFilterId: (value) => dispatch(setLocationFilterId(value)),
    onSetLocationFilterDataArray: (value) => dispatch(setLocationFilterDataArray(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterPills);
