import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setFilterMenuStatus } from '../actions';
import { makeSelectFilterActive, makeSelectFilterMenuStatus } from '../selectors';
import MainViewFilterPills from './MainViewFilterPills';
import FilterSwitch from '../../../utils/Components/FilterSwitch';
import { APP_MAX_MOBILE_WIDTH, MOBILE_PADDING_LR, TABLET_PADDING_LR } from '../../../utils/constants';

const FilterWrapper = styled.div`
  margin: 30px ${MOBILE_PADDING_LR} 0;
  max-width: 100%;
  display: flex;
  flex-wrap: no-wrap;
  align-content: flex-start;

  @media (min-width: ${APP_MAX_MOBILE_WIDTH}px) {
    margin: 30px ${TABLET_PADDING_LR} 0;
  }

  .on svg {
    margin: 0 auto;
  }
`;

export const FilterMenuMobile = ({ filterMenuStatus, onSetFilterMenuStatus, filterActive }) => {
  const handleButtonClick = () => {
    onSetFilterMenuStatus(!filterMenuStatus);
  };

  if (!filterActive > 0) {
    return (
      <FilterWrapper>
        <FilterSwitch onClick={() => handleButtonClick()}>
          <FontAwesomeIcon icon={faSlidersH} />
          <span>Filter</span>
        </FilterSwitch>
      </FilterWrapper>
    );
  }

  return (
    <FilterWrapper>
      <FilterSwitch className={'on'} onClick={() => handleButtonClick()}>
        <FontAwesomeIcon icon={faSlidersH} />
        <span>Filter</span>
      </FilterSwitch>
      <MainViewFilterPills />
    </FilterWrapper>
  );
};

FilterMenuMobile.propTypes = {
  onSetFilterMenuStatus: PropTypes.func,
  filterMenuStatus: PropTypes.bool,
  filterActive: PropTypes.number,
};

FilterMenuMobile.defaultProps = {
  onSetFilterMenuStatus: () => {},
  filterMenuStatus: false,
  filterActive: 0,
};

const mapStateToProps = createStructuredSelector({
  filterMenuStatus: makeSelectFilterMenuStatus(),
  filterActive: makeSelectFilterActive(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onSetFilterMenuStatus: (value) => dispatch(setFilterMenuStatus(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterMenuMobile);
