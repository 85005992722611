import React, { memo, useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { makeSelectCourses, makeSelectTotalResults } from '../App/selectors';
import Mobile from '../../components/CourseItem/Mobile';
import styled from 'styled-components';
import { orderBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSortAlt } from '@fortawesome/pro-light-svg-icons';
import Colors from '../../utils/Colors';
import FilterElement from '../../components/FilterElement';
import ResultCount from '../../utils/Components/ResultCounter';
import {
  APP_MAX_MOBILE_WIDTH,
  APP_MAX_TABLET_WIDTH,
  MOBILE_PADDING_LR,
  TABLET_PADDING_LR,
} from '../../utils/constants';
import SearchStatus from '../SearchStatus';

const MobileSort = styled.div`
  top: -62px;
  right: ${MOBILE_PADDING_LR};
  position: absolute;
  cursor: pointer;
  @media (min-width: ${APP_MAX_MOBILE_WIDTH}px) {
    padding-right: 15px;
    right: ${TABLET_PADDING_LR};
  }
  @media (min-width: ${APP_MAX_TABLET_WIDTH}px) {
    top: -52px;
  }
`;

const MobileOfferWrapper = styled.div`
  position: relative;
`;

const SortOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: block;
  background-color: ${Colors.white};
  width: 100%;
  height: 100vh;
  border: 8px solid ${Colors.yellow};
  z-index: 500;
  overflow: hidden;
  padding: 24px 20px;
`;

export const MobileDisplay = ({ courses, totalResults }) => {
  const [currentCourses, setCurrentCourses] = useState(courses);
  const [sortOverlay, setSortOverlay] = useState(false);
  const [activeSort, setActiveSort] = useState('standard');
  useEffect(() => {
    setCurrentCourses(courses);
  }, [courses]);

  const MobileView = () => currentCourses.map((course) => <Mobile course={course} key={course.id} />);

  const sortFilter = (filter) => {
    setCurrentCourses(orderBy(currentCourses, [filter], ['asc']));
    setActiveSort(filter);
    setSortOverlay(!sortOverlay);
  };

  const SortOverlayDisplay = () => {
    if (!sortOverlay) {
      return <></>;
    }
    return (
      <SortOverlay>
        <div>SORTIEREN</div>
        <FontAwesomeIcon
          icon={faTimes}
          onClick={() => setSortOverlay(!sortOverlay)}
          style={{ position: 'absolute', right: '20px', top: '24px' }}
        />
        <div onClick={() => sortFilter('relevance')}>
          <FilterElement title={'Relevanz'} status={activeSort === 'relevance'} />
        </div>
        <div onClick={() => sortFilter('field_course_start_end_date')}>
          <FilterElement title={'Datum'} status={activeSort === 'field_course_start_end_date'} />
        </div>
        <div onClick={() => sortFilter('location_names')}>
          <FilterElement title={'Standort'} status={activeSort === 'location_names'} />
        </div>
        <div onClick={() => sortFilter('name')}>
          <FilterElement title={'Name'} status={activeSort === 'name'} />
        </div>
      </SortOverlay>
    );
  };

  return (
    <MobileOfferWrapper>
      <SortOverlayDisplay />
      <MobileSort onClick={() => setSortOverlay(!sortOverlay)}>
        <FontAwesomeIcon icon={faSortAlt} style={{ paddingRight: '15px' }} />
        Sortieren
      </MobileSort>
      <ResultCount>{`${totalResults} Angebot${totalResults !== 1 ? 'e' : ''} gefunden`}</ResultCount>
      <MobileView />
      <SearchStatus />
    </MobileOfferWrapper>
  );
};

MobileDisplay.propTypes = {
  courses: PropTypes.array,
  totalResults: PropTypes.number,
};

MobileDisplay.defaultProps = {
  courses: [],
  totalResults: 0,
};

const mapStateToProps = createStructuredSelector({
  courses: makeSelectCourses(),
  totalResults: makeSelectTotalResults(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, memo)(MobileDisplay);
