import React, { memo, useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { makeSelectFilterMenuStatus, makeSelectTimeFilterDataArray, makeSelectTimeFilterId } from '../selectors';
import { toggleTimeFilterMenu } from './actions';
import ParentElement from '../../../components/FilterElement/ParentElement';
import { useInjectReducer } from '../../../utils/injectReducer';
import reducer from './reducer';

const key = 'time';

export const FilterCollapse = ({ onToggleTimeFilterMenu, timeFilterMenuStatus, timeFilterDataArray }) => {
  useInjectReducer({ key, reducer });
  const [bubbleString, setBubbleString] = useState('');

  useEffect(() => {
    let newBubbleString = '';
    if (timeFilterDataArray.length > 0) {
      newBubbleString = timeFilterDataArray.join(', ');
    }
    if (bubbleString !== newBubbleString) {
      setBubbleString(newBubbleString);
    }
  }, [timeFilterDataArray]);

  /**
   * Main return function.
   */
  return (
    <div onClick={() => onToggleTimeFilterMenu(timeFilterMenuStatus)}>
      <ParentElement title={'Zeit'} bubble={bubbleString} status={timeFilterMenuStatus} />
    </div>
  );
};

FilterCollapse.propTypes = {
  timeFilterDataArray: PropTypes.array,
  onToggleTimeFilterMenu: PropTypes.func,
  timeFilterMenuStatus: PropTypes.bool,
};

FilterCollapse.defaultProps = {
  timeFilterDataArray: [],
  onToggleTimeFilterMenu: () => {},
  timeFilterMenuStatus: true,
};

const mapStateToProps = createStructuredSelector({
  timeFilterId: makeSelectTimeFilterId(),
  timeFilterMenuStatus: makeSelectFilterMenuStatus(),
  timeFilterDataArray: makeSelectTimeFilterDataArray(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onToggleTimeFilterMenu: (value) => dispatch(toggleTimeFilterMenu(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterCollapse);
