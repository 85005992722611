import styled from 'styled-components';
import ResetButton from './ResetButton';
import Transitions from '../Transitions';
import Colors from '../Colors';

const LocalReset = styled(ResetButton)`
  width: 100%;
  margin: 0;
  padding: 15px 0;
  .svg-inline--fa {
    transition: all ${Transitions.timing} 0.4s;
    transform-origin: center;
    padding-right: 0;
  }

  &:active {
    background-color: ${Colors.greyAction};
    .svg-inline--fa {
      transform: rotate3d(0, 0, 1, -180deg);
    }
  }
  span {
    margin-left: 15px;
  }
`;

export default LocalReset;
