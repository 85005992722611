import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Transitions from '../../utils/Transitions';
import Colors from '../../utils/Colors';
import Pill from '../../utils/Components/Pill';

const FilterElementRow = styled.div`
  transition: ${Transitions.all};
  padding: 14px 16px 14px 5px;
  height: 50px;
  display: block;
  position: relative;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  border-bottom: 1px solid ${Colors.grey};
  cursor: pointer;
  &:hover {
    background-color: ${Colors.grey};
  }
  &:active {
    background-color: ${Colors.greyAction};
    .svg-inline--fa {
        transition: ${Transitions.all};
        transform: translate3d(3px, 0, 0);
      }
    }
  }
`;

const Title = styled.div`
  display: block;
  float: left;
`;

const Right = styled.div`
  float: right;
  display: block;
`;

export const ParentElement = ({ title, bubble, status }) => {
  const Bubble = () => {
    if (bubble.length === 0) {
      return <></>;
    }
    return <Pill>{bubble}</Pill>;
  };

  return (
    <FilterElementRow>
      <Title>{title}</Title>
      <Right>
        <Bubble />
        <FontAwesomeIcon icon={faChevronRight} style={{ fontSize: '22px' }} />
      </Right>
    </FilterElementRow>
  );
};

ParentElement.propTypes = {
  title: PropTypes.string,
  bubble: PropTypes.string,
  status: PropTypes.bool,
};

ParentElement.defaultProps = {
  title: '',
  bubble: '',
  status: false,
};

export default ParentElement;
