import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import CourseStatus, { SpanGrey } from './CourseStatus';
import Colors from '../../utils/Colors';
import Transitions from '../../utils/Transitions';
import Icons from '../../utils/Icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/pro-light-svg-icons';
import moment from 'moment';

const cellWidth = 'width: 160px;';

const CourseWrapper = styled.tr`
  max-width: 100%;
  border-top: 2px solid ${Colors.grey};
  transition: all ${Transitions.timing} 0.4s;
  border-spacing: 0;
  cursor: pointer;
  .icon {
    transition: ${Transitions.all};
    width: 26px;
    overflow: hidden;
    opacity: 0;
    margin-right: 9px;
    flex: 0 0 26px;
  }
  .link-cell {
    position: relative;
    width: 42px;
  }
  .offer-link {
    transition: ${Transitions.all};
    background-color: ${Colors.yellow};
    height: 100%;
    width: 42px;
    display: flex;
    position: absolute;
    top: 0;
    align-items: center;
    justify-content: center;
    color: ${Colors.textBlack};
    opacity: 0;

    &:hover {
      background-color: ${Colors.yellowHover};
    }
  }

  &.selected,
  &:hover {
    background-color: ${Colors.grey};
    .icon {
      opacity: 1;
    }
    .offer-link {
      opacity: 1;
    }
    .link-cell {
      width: 42px;
    }
  }
`;

const CourseTitle = styled.h3`
  font-size: 18px;
  color: ${Colors.textBlack};
  padding: 6px 0 4px;
  margin: 0;
  width: 100%;
  display: block;
  overflow: hidden;
  text-transform: none;
`;

const CellData = styled.td`
  ${cellWidth}
  padding: 0;
  font-size: 14px;
  color: ${Colors.textBlack};
  align-items: center;
  margin: 0 15px;
  min-height: 120px;
`;

const TitleWrapper = styled.td`
  max-width: 470px;
  padding: 0;
`;

const CellInner = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  align-items: center;
`;

const InvisibleLink = styled.a`
  color: ${Colors.textBlack};
  font-size: 14px;
  display: flex;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  padding: 25px 5px;
  min-height: 105px;
`;

const courseLocation = (locationArray) => {
  if (locationArray.length > 3) {
    return 'Andere';
  }
  if (locationArray.length > 0) {
    return locationArray.join(', ');
  }
  return '';
}

export const Desktop = ({ course }) => (
  <CourseWrapper>
    <TitleWrapper>
      <InvisibleLink href={course.url}>
        <CourseStatus
          current={course.field_participants_count}
          max={course.field_max_participants}
          min={course.field_min_participants}
        />
        {course.field_variant?.length > 0 ? <SpanGrey>&nbsp;|&nbsp;Variante {course.field_variant}</SpanGrey> : null}
        <CourseTitle>{course.name}</CourseTitle>
      </InvisibleLink>
    </TitleWrapper>
    <CellData>
      <InvisibleLink href={course.url}>
        <CellInner>
          <Icons icon={'location'} />
          {courseLocation(course.location_names)}
        </CellInner>
      </InvisibleLink>
    </CellData>
    <CellData>
      <InvisibleLink href={course.url}>
        <CellInner>
          <Icons icon={'calendar'} />
          {course.field_startdate_on_request === true ? (
            'auf Anfrage'
          ) : moment.unix(course.field_course_start_end_date).format('DD.MM.YYYY')}
        </CellInner>
      </InvisibleLink>
    </CellData>
    <CellData>
      <InvisibleLink href={course.url}>
        <CellInner>
          <Icons icon={'cost'} />
          {course.field_cost}
        </CellInner>
      </InvisibleLink>
    </CellData>
    <CellData>
      <InvisibleLink href={course.url}>
        <CellInner>
          <Icons icon={'start'} />
          {course.field_schedule_string?.replace(/&nbsp;/g, '\u00a0')}
        </CellInner>
      </InvisibleLink>
    </CellData>
    <td className={'link-cell'}>
      <a className={'offer-link'} href={course.url}>
        <FontAwesomeIcon icon={faArrowRight} />
      </a>
    </td>
  </CourseWrapper>
);

Desktop.propTypes = {
  course: PropTypes.object,
};

Desktop.defaultProps = {
  course: {},
};

export default Desktop;
