import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { setFilterMenuStatus } from '../actions';
import { makeSelectFilterMenuStatus } from '../selectors';
import SearchFilters from '../SearchFilters';
import SearchLocations from '../SearchLocations';
import SearchDegree from '../SearchDegree';
import FilterHeader from './FilterHeader';
import FilterReset from '../FilterReset';
import { toggleStructureFilterMenu } from '../SearchFilters/actions';
import FilterOverlayBlock from '../../../utils/Components/FilterOverlayBlock';
import ShowResultsButton from '../../../utils/Components/ShowResultsButton';
import SearchDays from '../SearchDays';
import SearchTime from '../SearchTime';
import FilterOverlayBackground from '../../../utils/Components/FilterOverlayBackground';
import FilterListWrapper from '../../../utils/Components/FilterListWrapper';

export const FilterOverlay = ({ filterMenuStatus, onSetFilterMenuStatus, onToggleStructureFilterMenu }) => {
  const [previousMenuStatus, setPreviousMenuStatus] = useState(false);

  useEffect(() => {
    const location = new URL(window.location.href);
    let params = new URLSearchParams(location.search);
    if (params.has('advanced')) {
      let newLocation = location.pathname;
      onSetFilterMenuStatus(true);
      params.delete('advanced');
      const newParams = params.toString();
      if (newParams.length > 0) {
        newLocation += `?${newParams}`;
      }
      window.history.replaceState({}, '', newLocation);
    }
  }, []);

  useEffect(() => {
    if (filterMenuStatus !== previousMenuStatus) {
      if (!filterMenuStatus) {
        onToggleStructureFilterMenu(false);
      }
      setPreviousMenuStatus(filterMenuStatus);
    }
  }, [filterMenuStatus]);

  const handleOverlayClick = () => {
    onSetFilterMenuStatus(false);
  };

  /**
   * Catch any click on filter menu to prevent premature closing.
   * @param event
   */
  const handleFilterBlockClick = (event) => {
    event.stopPropagation();
  };

  return (
    <FilterOverlayBackground
      className={filterMenuStatus ? 'filter-bg-visible' : ''}
      onClick={() => handleOverlayClick()}
    >
      <FilterOverlayBlock
        className={filterMenuStatus ? 'filter-block-visible' : ''}
        onClick={(event) => handleFilterBlockClick(event)}
      >
        <FilterHeader />
        <FilterReset passClass={'overlay'} />
        <FilterListWrapper>
          <SearchFilters />
          <SearchLocations />
          <SearchDegree />
          <SearchDays />
          <SearchTime />
        </FilterListWrapper>
        <ShowResultsButton onClick={() => onSetFilterMenuStatus(false)}>Resultate anzeigen</ShowResultsButton>
      </FilterOverlayBlock>
    </FilterOverlayBackground>
  );
};

FilterOverlay.propTypes = {
  onSetFilterMenuStatus: PropTypes.func,
  filterMenuStatus: PropTypes.bool,
  onToggleStructureFilterMenu: PropTypes.func,
};

FilterOverlay.defaultProps = {
  onSetFilterMenuStatus: () => {},
  filterMenuStatus: false,
  onToggleStructureFilterMenu: () => {},
};

const mapStateToProps = createStructuredSelector({
  filterMenuStatus: makeSelectFilterMenuStatus(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onSetFilterMenuStatus: (value) => dispatch(setFilterMenuStatus(value)),
    onToggleStructureFilterMenu: (value) => dispatch(toggleStructureFilterMenu(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterOverlay);
