import React, { memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { setFilterMenuStatus, setTimeFilterDataArray, setTimeFilterId } from '../actions';
import { loadCourses } from '../../App/actions';
import CollapsedList from '../../../utils/Components/CollapsedList';
import FilterCollapse from './FilterCollapse';
import FilterOverlayBlock from '../../../utils/Components/FilterOverlayBlock';
import { FilterHeaderBlock } from '../FilterOverlay/FilterHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import LocalReset from '../../../utils/Components/LocalReset';
import CurrentFilterResetBlock from '../../../components/CurrentFilterResetBlock';
import { useInjectReducer } from '../../../utils/injectReducer';
import reducer from './reducer';
import { initialState } from '../reducer';
import { makeSelectTimeFilterMenuStatus } from './selectors';
import { toggleTimeFilterMenu } from './actions';
import Items from './Items';
import ShowResultsButton from '../../../utils/Components/ShowResultsButton';
import FilterListWrapper from '../../../utils/Components/FilterListWrapper';

const key = 'time';

export function SearchTime({
  timeFilterMenuStatus,
  onToggleTimeFilterMenu,
  onSetTimeFilterDataArray,
  onSetTimeFilterId,
  onSetFilterMenuStatus,
}) {
  useInjectReducer({ key, reducer });

  const resetFilter = () => {
    onSetTimeFilterId(initialState.timeFilterId);
    onSetTimeFilterDataArray(initialState.timeFilterDataArray);
  };

  const handleResultsClick = () => {
    onToggleTimeFilterMenu(false);
    onSetFilterMenuStatus(false);
  };

  const CurrentFilterReset = () => (
    <LocalReset onClick={() => resetFilter()}>
      <CurrentFilterResetBlock />
    </LocalReset>
  );

  /**
   * Main return function.
   */
  return (
    <CollapsedList className={timeFilterMenuStatus ? 'open' : ''}>
      <FilterCollapse />
      <FilterOverlayBlock className={'item-list'}>
        <FilterHeaderBlock>
          <span>WOCHENTAG</span>
          <FontAwesomeIcon icon={faArrowLeft} onClick={() => onToggleTimeFilterMenu(false)} />
        </FilterHeaderBlock>
        <CurrentFilterReset />
        <FilterListWrapper>
          <Items />
        </FilterListWrapper>
        <ShowResultsButton onClick={() => handleResultsClick()}>Resultate anzeigen</ShowResultsButton>
      </FilterOverlayBlock>
    </CollapsedList>
  );
}

SearchTime.propTypes = {
  timeFilterMenuStatus: PropTypes.bool,
  onToggleTimeFilterMenu: PropTypes.func,
  onSetTimeFilterDataArray: PropTypes.func,
  onSetTimeFilterId: PropTypes.func,
  onSetFilterMenuStatus: PropTypes.func,
};

SearchTime.defaultProps = {
  timeFilterMenuStatus: false,
  onToggleTimeFilterMenu: () => {},
  onSetTimeFilterDataArray: () => {},
  onSetTimeFilterId: () => {},
  onSetFilterMenuStatus: () => {},
};

const mapStateToProps = createStructuredSelector({
  timeFilterMenuStatus: makeSelectTimeFilterMenuStatus(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onLoadCourses: () => dispatch(loadCourses()),
    onToggleTimeFilterMenu: (value) => dispatch(toggleTimeFilterMenu(value)),
    onSetTimeFilterDataArray: (value) => dispatch(setTimeFilterDataArray(value)),
    onSetTimeFilterId: (value) => dispatch(setTimeFilterId(value)),
    onSetFilterMenuStatus: (value) => dispatch(setFilterMenuStatus(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(SearchTime);
