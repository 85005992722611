import produce from 'immer';
import { TOGGLE_LOCATION_FILTER_MENU } from './constants';

export const initialState = {
  locationFilterMenuStatus: false,
};

/* eslint-disable default-case, no-param-reassign */
const locationReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TOGGLE_LOCATION_FILTER_MENU:
        draft.locationFilterMenuStatus = action.value;
        break;
      default:
        break;
    }
  });

export default locationReducer;
