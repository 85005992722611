import React, { memo, useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { makeSelectFilterMenuStatus, makeSelectDaysFilterDataArray, makeSelectDaysFilterId } from '../selectors';
import { toggleDaysFilterMenu } from './actions';
import ParentElement from '../../../components/FilterElement/ParentElement';
import { useInjectReducer } from '../../../utils/injectReducer';
import reducer from './reducer';

const key = 'days';

export const FilterCollapse = ({ onToggleDaysFilterMenu, daysFilterMenuStatus, daysFilterDataArray }) => {
  useInjectReducer({ key, reducer });
  const [bubbleString, setBubbleString] = useState('');

  useEffect(() => {
    let newBubbleString = '';
    if (daysFilterDataArray.length > 0) {
      newBubbleString = daysFilterDataArray.join(', ');
    }
    if (bubbleString !== newBubbleString) {
      setBubbleString(newBubbleString);
    }
  }, [daysFilterDataArray]);

  /**
   * Main return function.
   */
  return (
    <div onClick={() => onToggleDaysFilterMenu(daysFilterMenuStatus)}>
      <ParentElement title={'Wochentag'} bubble={bubbleString} status={daysFilterMenuStatus} />
    </div>
  );
};

FilterCollapse.propTypes = {
  daysFilterDataArray: PropTypes.array,
  onToggleDaysFilterMenu: PropTypes.func,
  daysFilterMenuStatus: PropTypes.bool,
};

FilterCollapse.defaultProps = {
  daysFilterDataArray: [],
  onToggleDaysFilterMenu: () => {},
  daysFilterMenuStatus: true,
};

const mapStateToProps = createStructuredSelector({
  daysFilterId: makeSelectDaysFilterId(),
  daysFilterMenuStatus: makeSelectFilterMenuStatus(),
  daysFilterDataArray: makeSelectDaysFilterDataArray(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onToggleDaysFilterMenu: (value) => dispatch(toggleDaysFilterMenu(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterCollapse);
