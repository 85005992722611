import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectAppIsMobile } from '../../App/selectors';
import FilterReset from './index';

export const FilterResetMobile = ({ appIsMobile }) => {
  if (appIsMobile) {
    return <FilterReset />;
  }

  return <></>;
};

FilterResetMobile.propTypes = {
  appIsMobile: PropTypes.bool,
};

FilterResetMobile.defaultProps = {
  appIsMobile: false,
};

const mapStateToProps = createStructuredSelector({
  appIsMobile: makeSelectAppIsMobile(),
});

export function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterResetMobile);
