import styled from 'styled-components';
import Colors from '../Colors';
import Transitions from '../Transitions';
import { APP_MAX_TABLET_WIDTH } from '../constants';

export const FilterOverlayBlock = styled.div`
  display: block;
  box-sizing: border-box;
  position: fixed;
  width: 100%;
  max-width: 415px;
  padding: 24px 20px;
  transition: ${Transitions.all};
  border: 8px solid ${Colors.yellow};
  height: 100%;
  background-color: ${Colors.white};
  top: 0;
  left: 0;
  transform: translate3d(-120%, 0, 0);
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  box-shadow: 0 0 60px rgba(0, 0, 0, 0.2);

  &::-webkit-scrollbar {
    display: none;
  }

  &.filter-block-visible {
    transform: none;

    @media (min-width: ${APP_MAX_TABLET_WIDTH}px) {
      button:first-of-type {
        padding-left: 0 !important;
      }
    }
  }
`;

export default FilterOverlayBlock;
