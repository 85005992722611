/**
 * Gets the repositories of the user from Github
 */

import { call, put, takeLatest, select } from 'redux-saga/effects';
import request from '../../utils/request';
import {
  LOAD_ALL_FILTER_ITEMS,
  SET_DAYS_FILTER_ID,
  SET_LOCATION_FILTER_ID,
  SET_DEGREE_FILTER_ID,
  SET_STRUCTURE_FILTER_COMPLEX,
  SET_TIME_FILTER_ID,
} from './constants';
import { API_PATH } from '../../utils/constants';
import {
  setStructureFilterContent,
  setLocationFilterContent,
  setDegreeFilterContent,
  setDaysFilterContent,
  setTimeFilterContent,
  setStructureFilterReadableContent,
  setLocationFilterReadableContent,
  setDegreeFilterReadableContent,
} from './actions';
import * as queryString from 'query-string';
import history from '../../utils/history';
import { makeSelectDegreeFilterReadable, makeSelectLocationFilterReadable, makeSelectStructureFilterReadable } from './selectors';
let lookupTable = {
  structure: {},
  location: {},
  degree: {},
};

export function* apiLoadAllFilterItems() {
  const options = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  try {
    const requestData = yield call(request, `${window.location.origin}${API_PATH}/filters/all`, options);
    flattenTree(requestData.structure, 'structure');
    yield put(setStructureFilterReadableContent(lookupTable.structure));
    yield put(setLocationFilterReadableContent(requestData.course_location));
    yield put(setDegreeFilterReadableContent(requestData.course_degree));
    yield put(setStructureFilterContent(requestData.structure));
    yield put(setLocationFilterContent(requestData.course_location));
    yield put(setDegreeFilterContent(requestData.course_degree));
    yield put(setDaysFilterContent(requestData.kurstage));
    yield put(setTimeFilterContent(requestData.kurszeiten));
  } catch (err) {
    console.error(err);
  }
}

export function* setUrlParams(action) {
  const initialQuery = queryString.parse(window.location.search);
  let values = queryString.parse(window.location.search);
  const filterValue = action.value;
  switch (action.type) {
    case SET_TIME_FILTER_ID:
      values['time'] = filterValue.length > 0 ? filterValue.join('_') : undefined;
      break;
    case SET_LOCATION_FILTER_ID:
      if (filterValue.length === 0) {
        values['location'] = undefined;
        values['area'] = undefined;
        break;
      }
      const readableLocationFilter = yield select(makeSelectLocationFilterReadable());
      values['location'] = filterValue.join('_');
      values['area'] = readableFilterValue(readableLocationFilter, filterValue);
      break;
    case SET_DEGREE_FILTER_ID:
      if (filterValue.length === 0) {
        values['degree'] = undefined;
        values['abschluss'] = undefined;
        break;
      }
      const readableDegreeFilter = yield select(makeSelectDegreeFilterReadable());
      values['degree'] = filterValue.join('_');
      values['abschluss'] = readableFilterValue(readableDegreeFilter, filterValue);
      break;
    case SET_STRUCTURE_FILTER_COMPLEX:
      if (filterValue.length === 0) {
        values['filter'] = undefined;
        values['courses'] = undefined;
        break;
      }
      const readableStructureFilter = yield select(makeSelectStructureFilterReadable());
      values['filter'] = filterValue.join('_');
      values['courses'] = filterValue.map((item) => readableStructureFilter[item]).join('-');
      break;
    case SET_DAYS_FILTER_ID:
      values['days'] = filterValue.length > 0 ? filterValue.join('_') : undefined;
      break;
    default:
      break;
  }
  const query = queryString.stringify(values);
  if (queryString.stringify(initialQuery) !== query) {
    history.push({ search: query });
  }
}

const readableFilterValue = (fullFilterArray, currentFilterValues) => {
  const plainTextFilters = [];
  currentFilterValues.map((item) => {
    return fullFilterArray.map((element) => {
      const elementValues = Object.values(element)[0];
      if (elementValues.id === item) {
        plainTextFilters.push(
          elementValues.name
            .replace(/[-_/. &]/g, '_')
            .replace(/__+/g, '_')
            .toLowerCase()
        );
      }
    })
  })
  return plainTextFilters.join('-')
}

const flattenTree = (assets, type) =>
  Object.entries(assets).map((item) => {
    const currentItem = item[1];
    if (currentItem.hasOwnProperty('children')) {
      flattenTree(currentItem.children, type);
    }
    return Object.assign(lookupTable[type], {
      [currentItem.id]: encodeURI(
        currentItem.name
          .replace(/[-_/. &]/g, '_')
          .replace(/__+/g, '_')
          .toLowerCase(),
      ),
      type,
    });
  });

/**
 * Root saga manages watcher lifecycle
 */
export default function* loadFilters() {
  yield takeLatest(LOAD_ALL_FILTER_ITEMS, apiLoadAllFilterItems);
  yield takeLatest(SET_TIME_FILTER_ID, setUrlParams);
  yield takeLatest(SET_STRUCTURE_FILTER_COMPLEX, setUrlParams);
  yield takeLatest(SET_LOCATION_FILTER_ID, setUrlParams);
  yield takeLatest(SET_DEGREE_FILTER_ID, setUrlParams);
  yield takeLatest(SET_DAYS_FILTER_ID, setUrlParams);
}
