import React, { memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { setFilterMenuStatus, setDaysFilterDataArray, setDaysFilterId } from '../actions';
import { loadCourses } from '../../App/actions';
import CollapsedList from '../../../utils/Components/CollapsedList';
import FilterCollapse from './FilterCollapse';
import FilterOverlayBlock from '../../../utils/Components/FilterOverlayBlock';
import { FilterHeaderBlock } from '../FilterOverlay/FilterHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import LocalReset from '../../../utils/Components/LocalReset';
import CurrentFilterResetBlock from '../../../components/CurrentFilterResetBlock';
import { useInjectReducer } from '../../../utils/injectReducer';
import reducer from './reducer';
import { initialState } from '../reducer';
import { makeSelectDaysFilterMenuStatus } from './selectors';
import { toggleDaysFilterMenu } from './actions';
import Items from './Items';
import ShowResultsButton from '../../../utils/Components/ShowResultsButton';
import FilterListWrapper from '../../../utils/Components/FilterListWrapper';

const key = 'days';

export function SearchDays({
  daysFilterMenuStatus,
  onToggleDaysFilterMenu,
  onSetDaysFilterDataArray,
  onSetDaysFilterId,
  onSetFilterMenuStatus,
}) {
  useInjectReducer({ key, reducer });

  const resetFilter = () => {
    onSetDaysFilterId(initialState.daysFilterId);
    onSetDaysFilterDataArray(initialState.daysFilterDataArray);
  };

  const handleResultsClick = () => {
    onToggleDaysFilterMenu(false);
    onSetFilterMenuStatus(false);
  };

  const CurrentFilterReset = () => (
    <LocalReset onClick={() => resetFilter()}>
      <CurrentFilterResetBlock />
    </LocalReset>
  );

  /**
   * Main return function.
   */
  return (
    <CollapsedList className={daysFilterMenuStatus ? 'open' : ''}>
      <FilterCollapse />
      <FilterOverlayBlock className={'item-list'}>
        <FilterHeaderBlock>
          <span>WOCHENTAG</span>
          <FontAwesomeIcon icon={faArrowLeft} onClick={() => onToggleDaysFilterMenu(false)} />
        </FilterHeaderBlock>
        <CurrentFilterReset />
        <FilterListWrapper>
          <Items />
        </FilterListWrapper>
        <ShowResultsButton onClick={() => handleResultsClick()}>Resultate anzeigen</ShowResultsButton>
      </FilterOverlayBlock>
    </CollapsedList>
  );
}

SearchDays.propTypes = {
  daysFilterMenuStatus: PropTypes.bool,
  onToggleDaysFilterMenu: PropTypes.func,
  onSetDaysFilterDataArray: PropTypes.func,
  onSetDaysFilterId: PropTypes.func,
  onSetFilterMenuStatus: PropTypes.func,
};

SearchDays.defaultProps = {
  daysFilterMenuStatus: false,
  onToggleDaysFilterMenu: () => {},
  onSetDaysFilterDataArray: () => {},
  onSetDaysFilterId: () => {},
  onSetFilterMenuStatus: () => {},
};

const mapStateToProps = createStructuredSelector({
  daysFilterMenuStatus: makeSelectDaysFilterMenuStatus(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onLoadCourses: () => dispatch(loadCourses()),
    onToggleDaysFilterMenu: (value) => dispatch(toggleDaysFilterMenu(value)),
    onSetDaysFilterDataArray: (value) => dispatch(setDaysFilterDataArray(value)),
    onSetDaysFilterId: (value) => dispatch(setDaysFilterId(value)),
    onSetFilterMenuStatus: (value) => dispatch(setFilterMenuStatus(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(SearchDays);
