import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { makeSelectFilterActive, makeSelectDegreeFilterContent } from '../selectors';
import { makeSelectPreSetDegree } from '../../App/selectors';
import { setDegreeFilterDataArray, setDegreeFilterId } from '../actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import initialStateConstants from '../initialStateConstants';

export const DegreePreFilter = ({
  degreeItems,
  preSetDegree,
  onSetDegreeFilterId,
  onSetDegreeFilterDataArray,
}) => {
  const appHistory = useHistory();
  const { search } = useLocation();
  const [queryParams, setQueryParams] = useState(queryString.parse(search)?.degree ?? '');

  /**
   * @implements useEffect()
   *
   * Checks if filter and pre-set filtering should be run once (new) filterable items
   * become available.
   */
  useEffect(() => {
    if (preSetDegree.length > 0 || queryParams.length > 0) {
      preFilter(queryParams);
    }
  }, [degreeItems]);

  /**
   * @implements useEffect()
   *
   * Checks if a query parameter for this filter has changed and
   * kicks-off updates.
   */
  useEffect(() => {
    let newQueryString = queryString.parse(search)?.degree ?? '';
    if (newQueryString === queryParams) {
      return null;
    }
    if (appHistory.action === 'POP') {
      preFilter(newQueryString);
    }
    setQueryParams(newQueryString);
  }, [search]);

  const preFilter = (newQueryString) => {
    if (degreeItems.length === 0) {
      return null;
    }
    let filterItems = [];
    if (preSetDegree.length > 0) {
      filterItems = preSetDegree;
    }
    if (newQueryString.length > 0) {
      filterItems = newQueryString.split('_');
    }
    if (filterItems?.length > 0) {
      onSetDegreeFilterId(filterItems);
      const degreeStrings = filterItems.map((currentItem) => {
        const result = degreeItems.find((item) => Object.keys(item)[0] === currentItem);
        return result[Object.keys(result)].name;
      });
      onSetDegreeFilterDataArray(degreeStrings);
    } else {
      resetFilter();
    }
  };

  const resetFilter = () => {
    onSetDegreeFilterId(initialStateConstants.degreeFilterId);
    onSetDegreeFilterDataArray(initialStateConstants.degreeFilterDataArray);
  };

  return <></>;
};

DegreePreFilter.propTypes = {
  degreeItems: PropTypes.array,
  onSetDegreeFilterId: PropTypes.func,
  onSetDegreeFilterDataArray: PropTypes.func,
  preSetDegree: PropTypes.array,
  filterActive: PropTypes.number,
};

DegreePreFilter.defaultProps = {
  degreeItems: [],
  onSetDegreeFilterId: () => {},
  onSetDegreeFilterDataArray: () => {},
  preSetDegree: [],
  filterActive: 0,
};

const mapStateToProps = createStructuredSelector({
  degreeItems: makeSelectDegreeFilterContent(),
  preSetDegree: makeSelectPreSetDegree(),
  filterActive: makeSelectFilterActive(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onSetDegreeFilterId: (value) => dispatch(setDegreeFilterId(value)),
    onSetDegreeFilterDataArray: (value) => dispatch(setDegreeFilterDataArray(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(DegreePreFilter);
