import React from 'react';
import PropTypes from 'prop-types';
import Location from './8-pos.svg';
import Calendar from './7-pos.svg';
import Cost from './1-pos.svg';
import Days from './6-pos.svg';
import styled from 'styled-components';

const Icon = styled.div`
  display: flex;
  justify-content: center;
`;

export const Icons = ({ icon }) => {
  const SelectedIcon = () => {
    switch (icon) {
      case 'days':
        return <img src={Days} alt={'Tage'} />;
      case 'start':
      case 'calendar':
        return <img src={Calendar} alt={'start'} />;
      case 'location':
        return <img src={Location} alt={'standort'} />;
      case 'cost':
        return <img src={Cost} alt={'kosten'} />;
      default:
        return <></>;
    }
  };

  return (
    <Icon className={'icon'}>
      <SelectedIcon />
    </Icon>
  );
};

Icons.propTypes = {
  icon: PropTypes.string,
};

Icons.defaultProps = {
  icon: '',
};

export default Icons;
