import styled from 'styled-components';
import Colors from '../Colors';
import Transitions from '../Transitions';
import { APP_MAX_MOBILE_WIDTH, APP_MAX_TABLET_WIDTH, MOBILE_PADDING_LR, TABLET_PADDING_LR } from '../constants';

export const ResetButton = styled.button`
  font-size: 18px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: block;
  max-width: 100%;
  text-align: left;
  border-bottom: 2px solid ${Colors.grey};
  margin: 15px ${MOBILE_PADDING_LR} 0;
  padding: 15px 0 0;

  .svg-inline--fa {
    transition: ${Transitions.all};
    font-size: 22px;
    padding-right: 12px;
  }

  &:hover {
    background-color: ${Colors.grey};
  }

  &:active {
    background-color: ${Colors.greyAction};
    .svg-inline--fa {
      transform: scale3d(0.9, 0.9, 1);
    }
  }
  @media (min-width: ${APP_MAX_MOBILE_WIDTH}px) {
    margin: 15px ${TABLET_PADDING_LR} 0;
  }
  @media (min-width: ${APP_MAX_TABLET_WIDTH}px) {
    margin: 0;
    padding-left: 30px;
    height: 54px;
    align-items: center;
  }

  &.overlay {
    width: 100%;
    padding: 15px 0;
    margin: 0;
  }
`;

export default ResetButton;
