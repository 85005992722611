import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { faSlidersH } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setFilterMenuStatus } from '../actions';
import { makeSelectFilterActive, makeSelectFilterMenuStatus } from '../selectors';
import MainViewFilterPills from './MainViewFilterPills';
import FilterReset from '../FilterReset';
import FilterSwitch from '../../../utils/Components/FilterSwitch';

const FilterWrapper = styled.div`
  margin-top: 30px;
  padding-left: 30px;
  display: flex;
  flex-wrap: no-wrap;
  align-content: flex-start;
`;

export const FilterMenuDesktop = ({ filterMenuStatus, onSetFilterMenuStatus, filterActive }) => {
  const handleButtonClick = () => {
    onSetFilterMenuStatus(!filterMenuStatus);
  };

  if (!filterActive > 0) {
    return (
      <FilterWrapper>
        <FilterSwitch onClick={() => handleButtonClick()}>
          <FontAwesomeIcon icon={faSlidersH} />
          <span>Filter</span>
        </FilterSwitch>
      </FilterWrapper>
    );
  }

  return (
    <>
      <FilterWrapper>
        <FilterSwitch onClick={() => handleButtonClick()}>
          <FontAwesomeIcon icon={faSlidersH} />
          <span>Filter</span>
        </FilterSwitch>
        <FilterReset />
      </FilterWrapper>
      <MainViewFilterPills />
    </>
  );
};

FilterMenuDesktop.propTypes = {
  onSetFilterMenuStatus: PropTypes.func,
  filterMenuStatus: PropTypes.bool,
  filterActive: PropTypes.number,
};

FilterMenuDesktop.defaultProps = {
  onSetFilterMenuStatus: () => {},
  filterMenuStatus: false,
  filterActive: 0,
};

const mapStateToProps = createStructuredSelector({
  filterMenuStatus: makeSelectFilterMenuStatus(),
  filterActive: makeSelectFilterActive(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onSetFilterMenuStatus: (value) => dispatch(setFilterMenuStatus(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterMenuDesktop);
