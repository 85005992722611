import React, { useState } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { Collapse } from '@nkbt/react-collapse/lib/Collapse';
import PropTypes from 'prop-types';
import CourseStatus, { SpanGrey } from './CourseStatus';
import Colors from '../../utils/Colors';
import Transitions from '../../utils/Transitions';
import Icons from '../../utils/Icons';
import moment from 'moment';
import { APP_MAX_MOBILE_WIDTH, MOBILE_PADDING_LR, TABLET_PADDING_LR } from '../../utils/constants';

const CourseWrapper = styled.div`
  margin: 0;
  border-top: 2px solid ${Colors.grey};
  padding: 17px ${MOBILE_PADDING_LR};
  transition: background-color ${Transitions.timing} 0.4s;
  &.selected,
  &:hover {
    background-color: ${Colors.grey};
  }
  @media (min-width: ${APP_MAX_MOBILE_WIDTH}px) {
    margin: 0 calc(${TABLET_PADDING_LR} - ${MOBILE_PADDING_LR});
  }
`;

const CourseTitle = styled.h3`
  font-size: 18px;
  color: ${Colors.textBlack};
  padding: 6px 0 4px;
  margin: 0;
  text-transform: none;
`;

const CenterIcon = styled.div`
  font-size: 22px;
  width: 100%;
  text-align: center;
  transition: transform ${Transitions.timing} 0.4s;
  &.inverted {
    transform: rotate(180deg);
  }

  &:active {
    transform: scale(0.8);
  }
`;

const CourseLocations = styled.div`
  font-size: 14px;
  color: ${Colors.textBlack};
`;

const DependentCollapsible = styled.div`
  .ReactCollapse--collapse {
    margin: 0;
    transition: all ${Transitions.timing} 0.4s;
    ul {
      margin: 0;
      padding: 8px 0;
    }
  }
`;

const CellDetail = styled.td`
  width: 50%;
  text-align: center;
  vertical-align: top;
  padding: 0;
  border-radius: 0;
`;
const InnerCell = styled.div`
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  margin: 0 auto;
  font-size: 14px;

  .icon {
    height: 42px;
    width: 100%;
    margin: 20px 0;

    img {
      height: 42px;
      width: auto;
    }
  }
`;

const OfferLink = styled.a`
  background-color: ${Colors.yellow};
  width: 100%;
  display: block;
  box-sizing: border-box;
  color: ${Colors.textBlack};
  text-decoration: none;
  padding: 15px 20px;
  margin-top: 20px;
  @media (min-width: 530px) {
    max-width: 360px;
    margin: 20px auto 0;
  }
  @media (min-width: 620px) {
    max-width: 460px;
    margin: 20px auto 0;
  }
`;

const MagicTable = styled.table`
  width: 100%;
  @media (min-width: 600px) {
    max-width: 550px;
    margin: 0 auto;
    tbody {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;

      tr {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        width: 50%;
      }
    }
  }
`;

const CourseRow = styled.tr`
  border: none;
`;

const courseLocation = (locationArray) => {
  if (locationArray.length > 3) {
    return 'Andere';
  }
  if (locationArray.length > 0) {
    return locationArray.join(', ');
  }
  return '';
}

export const Mobile = ({ course }) => {
  const [open, setOpen] = useState(false);

  return (
    <CourseWrapper className={open ? 'selected' : ''}>
      <CourseStatus
        current={course.field_participants_count}
        max={course.field_max_participants}
        min={course.field_min_participants}
      />
      {course.field_variant?.length > 0 ? <SpanGrey>&nbsp;|&nbsp;Variante {course.field_variant}</SpanGrey> : null}
      <CourseTitle>{course.name}</CourseTitle>
      <CourseLocations>{course.location_names.join(', ')}</CourseLocations>
      <>
        <CenterIcon onClick={() => setOpen(!open)} className={open ? 'inverted' : ''}>
          <FontAwesomeIcon icon={faChevronDown} />
        </CenterIcon>
        <DependentCollapsible>
          <Collapse isOpened={open}>
            <MagicTable>
              <tbody>
                <CourseRow>
                  <CellDetail>
                    <InnerCell>
                      <Icons icon={'location'} />
                      {courseLocation(course.location_names)}
                    </InnerCell>
                  </CellDetail>
                  <CellDetail>
                    <InnerCell>
                      <Icons icon={'calendar'} />
                      {course.field_startdate_on_request === true ? (
                        'auf Anfrage'
                      ) : moment.unix(course.field_course_start_end_date).format('DD.MM.YYYY')}
                    </InnerCell>
                  </CellDetail>
                </CourseRow>
                <CourseRow>
                  <CellDetail>
                    <InnerCell>
                      <Icons icon={'cost'} />
                      {course.field_cost}
                    </InnerCell>
                  </CellDetail>
                  <CellDetail>
                    <InnerCell>
                      <Icons icon={'days'} />
                      {course.field_schedule_string?.replace(/&nbsp;/g, '\u00a0')}
                    </InnerCell>
                  </CellDetail>
                </CourseRow>
              </tbody>
            </MagicTable>
            <OfferLink href={course.url}>Angebot öffnen</OfferLink>
          </Collapse>
        </DependentCollapsible>
      </>
    </CourseWrapper>
  );
};

Mobile.propTypes = {
  course: PropTypes.object,
};

Mobile.defaultProps = {
  course: {},
};

export default Mobile;
