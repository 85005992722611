import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGlobal = (state) => state.search || initialState;

const makeSelectSearchString = () => createSelector(selectGlobal, (searchState) => searchState.searchString);
const makeSelectFilterMenuStatus = () => createSelector(selectGlobal, (searchState) => searchState.filterMenuStatus);
const makeSelectFilterActive = () => createSelector(selectGlobal, (searchState) => searchState.filterActive);

// Structure
const makeSelectStructureFilterContent = () => createSelector(selectGlobal, (searchState) => searchState.structure);
const makeSelectStructureFilterId = () => createSelector(selectGlobal, (searchState) => searchState.structureFilterId);
const makeSelectStructureFilterComplex = () =>
  createSelector(selectGlobal, (searchState) => searchState.structureFilterComplex);
const makeSelectStructureFilterDataArray = () =>
  createSelector(selectGlobal, (searchState) => searchState.structureFilterDataArray);
const makeSelectStructureFilterReadable = () =>
  createSelector(selectGlobal, (searchState) => searchState.structureFilterReadable);
// Location
const makeSelectLocationFilterContent = () => createSelector(selectGlobal, (searchState) => searchState.location);
const makeSelectLocationFilterId = () => createSelector(selectGlobal, (searchState) => searchState.locationFilterId);
const makeSelectLocationFilterDataArray = () =>
  createSelector(selectGlobal, (searchState) => searchState.locationFilterDataArray);
const makeSelectLocationFilterReadable = () =>
  createSelector(selectGlobal, (searchState) => searchState.locationFilterReadable);
// Days
const makeSelectDaysFilterContent = () => createSelector(selectGlobal, (searchState) => searchState.days);
const makeSelectDaysFilterId = () => createSelector(selectGlobal, (searchState) => searchState.daysFilterId);
const makeSelectDaysFilterDataArray = () =>
  createSelector(selectGlobal, (searchState) => searchState.daysFilterDataArray);
// Time
const makeSelectTimeFilterContent = () => createSelector(selectGlobal, (searchState) => searchState.time);
const makeSelectTimeFilterId = () => createSelector(selectGlobal, (searchState) => searchState.timeFilterId);
const makeSelectTimeFilterDataArray = () =>
  createSelector(selectGlobal, (searchState) => searchState.timeFilterDataArray);

// Degree
const makeSelectDegreeFilterContent = () => createSelector(selectGlobal, (searchState) => searchState.degree);
const makeSelectDegreeFilterId = () => createSelector(selectGlobal, (searchState) => searchState.degreeFilterId);
const makeSelectDegreeFilterDataArray = () =>
  createSelector(selectGlobal, (searchState) => searchState.degreeFilterDataArray);
const makeSelectDegreeFilterReadable = () =>
  createSelector(selectGlobal, (searchState) => searchState.degreeFilterReadable);

export {
  makeSelectSearchString,
  makeSelectFilterMenuStatus,
  makeSelectFilterActive,
  // Structure
  makeSelectStructureFilterId,
  makeSelectStructureFilterContent,
  makeSelectStructureFilterComplex,
  makeSelectStructureFilterDataArray,
  makeSelectStructureFilterReadable,
  // Location
  makeSelectLocationFilterId,
  makeSelectLocationFilterContent,
  makeSelectLocationFilterDataArray,
  makeSelectLocationFilterReadable,
  // Days
  makeSelectDaysFilterContent,
  makeSelectDaysFilterId,
  makeSelectDaysFilterDataArray,
  // Time
  makeSelectTimeFilterContent,
  makeSelectTimeFilterId,
  makeSelectTimeFilterDataArray,
  // Degree
  makeSelectDegreeFilterId,
  makeSelectDegreeFilterContent,
  makeSelectDegreeFilterDataArray,
  makeSelectDegreeFilterReadable,
};
