import React, { memo, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import {
  makeSelectLocationFilterContent,
  makeSelectLocationFilterDataArray,
  makeSelectLocationFilterId,
} from '../selectors';
import { makeSelectLocationFilterMenuStatus } from './selectors';
import { loadCourses } from '../../App/actions';
import { setLocationFilterDataArray, setLocationFilterId } from '../actions';
import { toggleLocationFilterMenu } from './actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import FilterElementRadio from '../../../components/FilterElement/FilterElementRadio';

export const Items = ({
  locationItems,
  onSetLocationFilterId,
  onLoadCourses,
  locationFilterId,
  locationFilterDataArray,
  onSetLocationFilterDataArray,
}) => {
  useEffect(() => {
    onLoadCourses();
  }, [locationFilterId]);

  const toggleFilterIds = (item) => {
    const itemIndex = locationFilterId.indexOf(item.id);
    if (itemIndex >= 0) {
      onSetLocationFilterId([...locationFilterId.slice(0, itemIndex), ...locationFilterId.slice(itemIndex + 1)]);
      onSetLocationFilterDataArray([
        ...locationFilterDataArray.slice(0, itemIndex),
        ...locationFilterDataArray.slice(itemIndex + 1),
      ]);
    } else {
      onSetLocationFilterId([item.id, ...locationFilterId]);
      onSetLocationFilterDataArray([item.name, ...locationFilterDataArray]);
    }
  };

  if (locationItems.length === 0) {
    return <></>;
  }

  return locationItems.map((entry) => {
    const item = Object.entries(entry)[0];
    return (
      <div key={item[1].id} onClick={() => toggleFilterIds(item[1])}>
        <FilterElementRadio
          title={item[1].name}
          status={locationFilterId.indexOf(item[1].id) >= 0}
          bubble={''}
          position={0}
        />
      </div>
    );
  });
};

Items.propTypes = {
  onLoadCourses: PropTypes.func,
  locationItems: PropTypes.array,
  onSetLocationFilterId: PropTypes.func,
  locationFilterDataArray: PropTypes.array,
};

Items.defaultProps = {
  onLoadCourses: () => {},
  locationItems: [],
  locationFilterDataArray: [],
  onSetLocationFilterId: () => {},
};

const mapStateToProps = createStructuredSelector({
  locationFilterId: makeSelectLocationFilterId(),
  locationItems: makeSelectLocationFilterContent(),
  locationFilterMenuStatus: makeSelectLocationFilterMenuStatus(),
  locationFilterDataArray: makeSelectLocationFilterDataArray(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onLoadCourses: () => dispatch(loadCourses()),
    onSetLocationFilterId: (value) => dispatch(setLocationFilterId(value)),
    onToggleLocationFilterMenu: (value) => dispatch(toggleLocationFilterMenu(value)),
    onSetLocationFilterDataArray: (value) => dispatch(setLocationFilterDataArray(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Items);
