import React, { memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { makeSelectAppIsMobile } from '../App/selectors';
import MobileDisplay from './MobileDisplay';
import DesktopDisplay from './DesktopDisplay';

export const CoursesBlock = ({ appIsMobile }) => {
  if (appIsMobile) {
    return <MobileDisplay />;
  }

  return <DesktopDisplay />;
};

CoursesBlock.propTypes = {
  appIsMobile: PropTypes.bool,
};

CoursesBlock.defaultProps = {
  appIsMobile: false,
};

const mapStateToProps = createStructuredSelector({
  appIsMobile: makeSelectAppIsMobile(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, memo)(CoursesBlock);
