import React, { memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { setFilterMenuStatus, setDegreeFilterDataArray, setDegreeFilterId } from '../actions';
import { loadCourses } from '../../App/actions';
import CollapsedList from '../../../utils/Components/CollapsedList';
import FilterCollapse from './FilterCollapse';
import FilterOverlayBlock from '../../../utils/Components/FilterOverlayBlock';
import { FilterHeaderBlock } from '../FilterOverlay/FilterHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import LocalReset from '../../../utils/Components/LocalReset';
import CurrentFilterResetBlock from '../../../components/CurrentFilterResetBlock';
import { useInjectReducer } from '../../../utils/injectReducer';
import reducer from './reducer';
import { initialState } from '../reducer';
import { makeSelectDegreeFilterMenuStatus } from './selectors';
import { toggleDegreeFilterMenu } from './actions';
import Items from './Items';
import ShowResultsButton from '../../../utils/Components/ShowResultsButton';
import { makeSelectPreSetDegree } from '../../App/selectors';
import FilterListWrapper from '../../../utils/Components/FilterListWrapper';

const key = 'degree';

export function SearchDegrees({
  degreeFilterMenuStatus,
  onToggleDegreeFilterMenu,
  onSetDegreeFilterDataArray,
  onSetDegreeFilterId,
  onSetFilterMenuStatus,
}) {
  useInjectReducer({ key, reducer });

  // useEffect(() => {
  //   if (preSetDegree.length > 0 && degreeDataFilterArray.length === 0) {
  //     onSetDegreeFilterId(preSetDegree);
  //   }
  // }, [preSetDegree]);
  //
  // useEffect(() => {
  //   if (preSetDegree.length > 0 && degreeDataFilterArray.length === 0) {
  //     let degrees = [];
  //     preSetDegree.map((item) => degrees.push(degreeItems[findKey(degreeItems, item)][item].name));
  //     onSetDegreeFilterDataArray(degrees);
  //   }
  // }, [degreeItems]);
  //
  const resetFilter = () => {
    onSetDegreeFilterId(initialState.degreeFilterId);
    onSetDegreeFilterDataArray(initialState.degreeFilterDataArray);
  };

  const handleResultsClick = () => {
    onToggleDegreeFilterMenu(false);
    onSetFilterMenuStatus(false);
  };

  const CurrentFilterReset = () => (
    <LocalReset onClick={() => resetFilter()}>
      <CurrentFilterResetBlock />
    </LocalReset>
  );

  /**
   * Main return function.
   */
  return (
    <CollapsedList className={degreeFilterMenuStatus ? 'open' : ''}>
      <FilterCollapse />
      <FilterOverlayBlock className={'item-list'}>
        <FilterHeaderBlock>
          <span>STANDORT</span>
          <FontAwesomeIcon icon={faArrowLeft} onClick={() => onToggleDegreeFilterMenu(false)} />
        </FilterHeaderBlock>
        <CurrentFilterReset />
        <FilterListWrapper>
          <Items />
        </FilterListWrapper>
        <ShowResultsButton onClick={() => handleResultsClick()}>Resultate anzeigen</ShowResultsButton>
      </FilterOverlayBlock>
    </CollapsedList>
  );
}

SearchDegrees.propTypes = {
  degreeFilterMenuStatus: PropTypes.bool,
  onToggleDegreeFilterMenu: PropTypes.func,
  onSetDegreeFilterDataArray: PropTypes.func,
  onSetDegreeFilterId: PropTypes.func,
  onSetFilterMenuStatus: PropTypes.func,
};

SearchDegrees.defaultProps = {
  degreeFilterMenuStatus: false,
  onToggleDegreeFilterMenu: () => {},
  onSetDegreeFilterDataArray: () => {},
  onSetDegreeFilterId: () => {},
  onSetFilterMenuStatus: () => {},
};

const mapStateToProps = createStructuredSelector({
  degreeFilterMenuStatus: makeSelectDegreeFilterMenuStatus(),
  preSetDegree: makeSelectPreSetDegree(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onLoadCourses: () => dispatch(loadCourses()),
    onToggleDegreeFilterMenu: (value) => dispatch(toggleDegreeFilterMenu(value)),
    onSetDegreeFilterDataArray: (value) => dispatch(setDegreeFilterDataArray(value)),
    onSetDegreeFilterId: (value) => dispatch(setDegreeFilterId(value)),
    onSetFilterMenuStatus: (value) => dispatch(setFilterMenuStatus(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(SearchDegrees);
