import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { faSearch } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { setSearchString } from '../actions';
import Colors from '../../../utils/Colors';
import {
  APP_MAX_MOBILE_WIDTH,
  APP_MAX_TABLET_WIDTH,
  MOBILE_PADDING_LR,
  TABLET_PADDING_LR,
} from '../../../utils/constants';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import history from '../../../utils/history';
import { makeSelectSearchString } from '../selectors';
import { loadCourses } from '../../App/actions';
import { useInjectSaga } from '../../../utils/injectSaga';
import saga from '../saga';
import { useInjectReducer } from '../../../utils/injectReducer';
import reducer from '../reducer';

const searchBoxHeight = '52px';
const key = 'search';

const SearchInput = styled.input`
  height: ${searchBoxHeight};
  padding: 20px 10px 16px;
  font-size: 18px;
  width: 100%;
  border: none;
`;

const SearchButton = styled.button`
  background-color: ${Colors.yellow};
  width: 52px;
  height: ${searchBoxHeight};
  border: none;
  margin: 0;
  padding: 0;
  font-size: 22px;
`;

const SearchBox = styled.div`
  display: flex;
  flex-wrap: nowrap;
  max-width: 100%;
  margin: 0 ${MOBILE_PADDING_LR};
  border: 1px solid ${Colors.textBlack};
  @media (min-width: ${APP_MAX_MOBILE_WIDTH}px) {
    margin: 0 ${TABLET_PADDING_LR};
  }
  @media (min-width: ${APP_MAX_TABLET_WIDTH}px) {
    max-width: 560px;
    width: 560px;
    margin: 0;
    float: left;
  }
`;

export function StringSearch({ searchString, onSetSearchString, onLoadCourses }) {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });

  const { search } = useLocation();
  const [currentString, setCurrentString] = useState('');
  const queryParams = queryString.parse(search);
  const urlSearchString = queryParams.search;
  useEffect(() => {
    if (urlSearchString !== undefined) {
      setCurrentString(urlSearchString);
      onSetSearchString(urlSearchString);
    }
  }, []);

  useEffect(() => {
    if (urlSearchString !== undefined) {
      if (urlSearchString !== currentString) {
        onSetSearchString(urlSearchString);
      }
      setCurrentString(urlSearchString);
    }
  }, [search]);

  useEffect(() => {
    const { length } = searchString.length;
    switch (true) {
      case length === 1:
        break;
      default:
        onLoadCourses();
    }
  }, [searchString]);

  const onControlKeys = (event) => {
    switch (event.key) {
      case 'Enter':
        executeStringSearch();
        break;
      case 'Escape':
        setCurrentString('');
        break;
      default:
        break;
    }
    return null;
  };

  const executeStringSearch = () => {
    onSetSearchString(currentString);
    queryParams['search'] = currentString;
    history.push({ search: queryString.stringify(queryParams) });
  };

  return (
    <SearchBox>
      <SearchInput
        className="search no-autoinit browser-default"
        value={currentString}
        onKeyUp={(event) => onControlKeys(event)}
        onChange={(event) => setCurrentString(event.target.value)}
        type={'text'}
        style={{ boxSizing: 'border-box' }}
        placeholder={'Weiterbildung suchen'}
      />
      <SearchButton onClick={() => executeStringSearch()}>
        <FontAwesomeIcon icon={faSearch} />
      </SearchButton>
    </SearchBox>
  );
}

StringSearch.propTypes = {
  onLoadCourses: PropTypes.func,
  onSetSearchString: PropTypes.func,
  searchString: PropTypes.string,
};

StringSearch.defaultProps = {
  onLoadCourses: () => {},
  onSetSearchString: () => {},
  searchString: '',
};

const mapStateToProps = createStructuredSelector({
  searchString: makeSelectSearchString(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onLoadCourses: () => dispatch(loadCourses()),
    onSetSearchString: (value) => dispatch(setSearchString(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(StringSearch);
