import styled from 'styled-components';
import Colors from '../Colors';
import Transitions from '../Transitions';

export const FilterOverlayBackground = styled.div`
  transition: ${Transitions.all};
  display: block;
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: ${Colors.overlay};
  top: 0;
  left: 0;
  overflow: none;
  opacity: 0;
  z-index: 1000;
  pointer-events: none;
  &.filter-bg-visible {
    pointer-events: all;
    opacity: 1;
  }
`;

export default FilterOverlayBackground;
