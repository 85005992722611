import styled from 'styled-components';
import Colors from '../Colors';
import Pill from './Pill';
import Transitions from '../Transitions';

export const PillYellow = styled(Pill)`
  background-color: ${Colors.yellow};
  transition: ${Transitions.all};
  max-width: 200px;
  cursor: pointer;
  &:hover {
    background-color: ${Colors.yellowHover};
  }
  &:active {
    transform: scale3d(0.95, 0.95, 1);
  }

  .svg-inline--fa {
    padding-left: 10px;
  }
`;

export const PillText = styled.span`
  max-width: 148px;
  overflow: hidden;
  white-space: nowrap;
  display: block;
  text-overflow: ellipsis;
  float: left;
`;

export default PillYellow;
