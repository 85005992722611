import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndo } from '@fortawesome/pro-light-svg-icons';

export const CurrentFilterResetBlock = () => (
  <>
    <FontAwesomeIcon icon={faUndo} />
    <span>Filter leeren</span>
  </>
);

export default CurrentFilterResetBlock;
