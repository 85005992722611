import produce from 'immer';

import { TOGGLE_STRUCTURE_FILTER_MENU } from './constants';

export const initialState = {
  structureFilterMenuStatus: false,
};

/* eslint-disable default-case, no-param-reassign */
const filterReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TOGGLE_STRUCTURE_FILTER_MENU:
        draft.structureFilterMenuStatus = action.value;
        break;
      default:
        break;
    }
  });

export default filterReducer;
