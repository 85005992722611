import { createSelector } from 'reselect';
import { initialState } from './reducer';

const selectGlobal = (state) => state.global || initialState;

const makeSelectCourses = () => createSelector(selectGlobal, (globalState) => globalState.courses);

const makeSelectAppIsMobile = () => createSelector(selectGlobal, (globalState) => globalState.appIsMobile);

const makeSelectTotalResults = () => createSelector(selectGlobal, (globalState) => globalState.totalResults);

const makeSelectFacets = () => createSelector(selectGlobal, (globalState) => globalState.facets);

const makeSelectPreSetLocation = () => createSelector(selectGlobal, (globalState) => globalState.preSetLocation);

const makeSelectPreSetDegree = () => createSelector(selectGlobal, (globalState) => globalState.preSetDegree);

const makeSelectPreSetStructure = () => createSelector(selectGlobal, (globalState) => globalState.preSetStructure);

const makeSelectAppIsLoading = () => createSelector(selectGlobal, (globalState) => globalState.appIsLoading);

export {
  makeSelectFacets,
  makeSelectCourses,
  makeSelectTotalResults,
  makeSelectPreSetLocation,
  makeSelectPreSetDegree,
  makeSelectPreSetStructure,
  makeSelectAppIsMobile,
  makeSelectAppIsLoading,
};
