import React, { memo, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { makeSelectDaysFilterContent, makeSelectDaysFilterDataArray, makeSelectDaysFilterId } from '../selectors';
import { loadCourses } from '../../App/actions';
import { setDaysFilterDataArray, setDaysFilterId } from '../actions';
import { toggleDaysFilterMenu } from './actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import FilterElementRadio from '../../../components/FilterElement/FilterElementRadio';

export const Items = ({
  daysItems,
  onSetDaysFilterId,
  onLoadCourses,
  daysFilterId,
  daysFilterDataArray,
  onSetDaysFilterDataArray,
}) => {
  useEffect(() => {
    onLoadCourses();
  }, [daysFilterId]);

  const toggleFilterIds = (item) => {
    const itemIndex = daysFilterId.indexOf(item.id);
    if (itemIndex >= 0) {
      onSetDaysFilterId([...daysFilterId.slice(0, itemIndex), ...daysFilterId.slice(itemIndex + 1)]);
      onSetDaysFilterDataArray([
        ...daysFilterDataArray.slice(0, itemIndex),
        ...daysFilterDataArray.slice(itemIndex + 1),
      ]);
    } else {
      onSetDaysFilterId([item.id, ...daysFilterId]);
      onSetDaysFilterDataArray([item.name, ...daysFilterDataArray]);
    }
  };

  if (daysItems.length === 0) {
    return <>Days</>;
  }

  let sortDayItems = [...daysItems];

  // Sort weekdays NOT alphabetically but by id.
  sortDayItems.sort((a, b) => {
    return a[Object.keys(a)[0]].id - b[Object.keys(b)[0]].id;
  });

  return sortDayItems.map((entry) => {
    const item = Object.entries(entry)[0];
    return (
      <div key={item[1].id} onClick={() => toggleFilterIds(item[1])}>
        <FilterElementRadio
          title={item[1].name}
          status={daysFilterId.indexOf(item[1].id) >= 0}
          bubble={''}
          position={0}
        />
      </div>
    );
  });
};

Items.propTypes = {
  onLoadCourses: PropTypes.func,
  daysItems: PropTypes.array,
  onSetDaysFilterId: PropTypes.func,
  daysFilterDataArray: PropTypes.array,
};

Items.defaultProps = {
  onLoadCourses: () => {},
  daysItems: [],
  daysFilterDataArray: [],
  onSetDaysFilterId: () => {},
};

const mapStateToProps = createStructuredSelector({
  daysFilterId: makeSelectDaysFilterId(),
  daysItems: makeSelectDaysFilterContent(),
  daysFilterDataArray: makeSelectDaysFilterDataArray(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onLoadCourses: () => dispatch(loadCourses()),
    onSetDaysFilterId: (value) => dispatch(setDaysFilterId(value)),
    onToggleDaysFilterMenu: (value) => dispatch(toggleDaysFilterMenu(value)),
    onSetDaysFilterDataArray: (value) => dispatch(setDaysFilterDataArray(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Items);
