import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  setDaysFilterDataArray,
  setDaysFilterId,
  setLocationFilterDataArray,
  setLocationFilterId,
  setDegreeFilterDataArray,
  setDegreeFilterId,
  setStructureFilterComplex,
  setStructureFilterDataArray,
  setStructureFilterId,
  setTimeFilterDataArray,
  setTimeFilterId,
} from '../actions';
import { loadCourses } from '../../App/actions';
import { toggleStructureFilterMenu } from '../SearchFilters/actions';
import ResetButton from '../../../utils/Components/ResetButton';
import initialStateConstants from '../initialStateConstants';

export const FilterReset = ({
  passClass,
  onLoadCourses,
  onSetStructureFilterId,
  onSetStructureFilterComplex,
  onSetLocationFilterId,
  onSetLocationFilterDataArray,
  onSetDegreeFilterId,
  onSetDegreeFilterDataArray,
  onSetStructureFilterDataArray,
  onToggleStructureFilterMenu,
  onSetTimeFilterDataArray,
  onSetDaysFilterDataArray,
  onSetDaysFilterId,
  onSetTimeFilterId,
}) => {
  const resetAllFilters = () => {
    onSetStructureFilterId(initialStateConstants.structureFilterId);
    onSetStructureFilterComplex(initialStateConstants.structureFilterComplex);
    onSetStructureFilterDataArray(initialStateConstants.structureFilterDataArray);
    onSetLocationFilterId(initialStateConstants.locationFilterId);
    onSetLocationFilterDataArray(initialStateConstants.locationFilterDataArray);
    onSetDegreeFilterId(initialStateConstants.degreeFilterId);
    onSetDegreeFilterDataArray(initialStateConstants.degreeFilterDataArray);
    onSetTimeFilterId(initialStateConstants.locationFilterId);
    onSetDaysFilterId(initialStateConstants.daysFilterId);
    onSetDaysFilterDataArray(initialStateConstants.daysFilterDataArray);
    onSetTimeFilterDataArray(initialStateConstants.timeFilterDataArray);
    onToggleStructureFilterMenu();
    onLoadCourses();
  };

  return (
    <ResetButton className={passClass ?? ''} onClick={() => resetAllFilters()}>
      <FontAwesomeIcon icon={faTrashAlt} />
      <span>Filter Zurücksetzen</span>
    </ResetButton>
  );
};

FilterReset.propTypes = {
  passClass: PropTypes.string,
  onLoadCourses: PropTypes.func,
  onSetLocationFilterId: PropTypes.func,
  onSetLocationFilterDataArray: PropTypes.func,
  onSetStructureFilterId: PropTypes.func,
  onSetStructureFilterComplex: PropTypes.func,
  onSetStructureFilterDataArray: PropTypes.func,
  onToggleStructureFilterMenu: PropTypes.func,
  onSetDegreeFilterId: PropTypes.func,
  onSetDegreeFilterDataArray: PropTypes.func,
  onSetDaysFilterDataArray: PropTypes.func,
  onSetTimeFilterDataArray: PropTypes.func,
  onSetDaysFilterId: PropTypes.func,
  onSetTimeFilterId: PropTypes.func,
};

FilterReset.defaultProps = {
  passClass: '',
  onLoadCourses: () => {},
  onSetLocationFilterId: () => {},
  onSetLocationFilterDataArray: () => {},
  onSetStructureFilterId: () => {},
  onSetStructureFilterComplex: () => {},
  onSetStructureFilterDataArray: () => {},
  onToggleStructureFilterMenu: () => {},
  onSetDegreeFilterId: () => {},
  onSetDegreeFilterDataArray: () => {},
  onSetDaysFilterId: () => {},
  onSetTimeFilterId: () => {},
  onSetDaysFilterDataArray: () => {},
  onSetTimeFilterDataArray: () => {},
};

FilterReset.defaultProps = {};

const mapStateToProps = createStructuredSelector({});

export function mapDispatchToProps(dispatch) {
  return {
    onSetStructureFilterId: (value) => dispatch(setStructureFilterId(value)),
    onSetStructureFilterComplex: (value) => dispatch(setStructureFilterComplex(value)),
    onSetLocationFilterId: (value) => dispatch(setLocationFilterId(value)),
    onSetLocationFilterDataArray: (value) => dispatch(setLocationFilterDataArray(value)),
    onSetStructureFilterDataArray: (value) => dispatch(setStructureFilterDataArray(value)),
    onToggleStructureFilterMenu: () => dispatch(toggleStructureFilterMenu(false)),
    onSetDegreeFilterId: (value) => dispatch(setDegreeFilterId(value)),
    onSetDegreeFilterDataArray: (value) => dispatch(setDegreeFilterDataArray(value)),
    onSetDaysFilterDataArray: (value) => dispatch(setDaysFilterDataArray(value)),
    onSetTimeFilterDataArray: (value) => dispatch(setTimeFilterDataArray(value)),
    onSetTimeFilterId: (value) => dispatch(setTimeFilterId(value)),
    onSetDaysFilterId: (value) => dispatch(setDaysFilterId(value)),
    onLoadCourses: () => dispatch(loadCourses()),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterReset);
