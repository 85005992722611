import React, { memo, useEffect, useLayoutEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import {
  makeSelectDaysFilterDataArray,
  makeSelectLocationFilterDataArray,
  makeSelectDegreeFilterDataArray,
  makeSelectStructureFilterComplex,
  makeSelectStructureFilterContent,
  makeSelectTimeFilterDataArray,
} from '../selectors';
import { setFilterActive, setStructureFilterId } from '../actions';
import { loadCourses } from '../../App/actions';
import StructurePreFilter from '../SearchFilters/PreFilter';
import LocationPreFilter from '../SearchLocations/PreFilter';
import DegreePreFilter from '../SearchDegree/PreFilter';
import TimePreFilter from '../SearchTime/PreFilter';
import DaysPreFilter from '../SearchDays/PreFilter';

export const StructureFilter = ({
  onLoadCourses,
  onSetStructureFilterId,
  structureFilterComplex,
  locationFilterDataArray,
  degreeFilterDataArray,
  daysFilterDataArray,
  timeFilterDataArray,
  onSetFilterActive,
}) => {
  useLayoutEffect(() => {
    onSetStructureFilterId(structureFilterComplex[0] ?? '');
    onLoadCourses();
  }, [structureFilterComplex]);

  useEffect(() => {
    let filterLength = 0;
    filterLength += structureFilterComplex.length;
    filterLength += locationFilterDataArray.length;
    filterLength += degreeFilterDataArray.length;
    filterLength += daysFilterDataArray.length;
    filterLength += timeFilterDataArray.length;
    onSetFilterActive(filterLength);
  }, [structureFilterComplex, locationFilterDataArray, daysFilterDataArray, timeFilterDataArray, degreeFilterDataArray]);

  /**
   * Main return function.
   */
  return (
    <>
      <StructurePreFilter />
      <LocationPreFilter />
      <DegreePreFilter />
      <TimePreFilter />
      <DaysPreFilter />
    </>
  );
};

StructureFilter.propTypes = {
  onLoadCourses: PropTypes.func,
  onSetStructureFilterId: PropTypes.func,
  structureFilterComplex: PropTypes.array,
  locationFilterDataArray: PropTypes.array,
  degreeFilterDataArray: PropTypes.array,
  daysFilterDataArray: PropTypes.array,
  timeFilterDataArray: PropTypes.array,
  onSetFilterActive: PropTypes.func,
};

StructureFilter.defaultProps = {
  onLoadCourses: () => {},
  onSetStructureFilterId: () => {},
  structureFilterComplex: [],
  locationFilterDataArray: [],
  degreeFilterDataArray: [],
  daysFilterDataArray: [],
  timeFilterDataArray: [],
  onSetFilterActive: () => {},
};

const mapStateToProps = createStructuredSelector({
  structureItems: makeSelectStructureFilterContent(),
  structureFilterComplex: makeSelectStructureFilterComplex(),
  locationFilterDataArray: makeSelectLocationFilterDataArray(),
  degreeFilterDataArray: makeSelectDegreeFilterDataArray(),
  daysFilterDataArray: makeSelectDaysFilterDataArray(),
  timeFilterDataArray: makeSelectTimeFilterDataArray(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onLoadCourses: () => dispatch(loadCourses()),
    onSetStructureFilterId: (value) => dispatch(setStructureFilterId(value)),
    onSetFilterActive: (value) => dispatch(setFilterActive(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(StructureFilter);
