export const Colors = {
  green: '#96C31E',
  yellow: '#FFDC00',
  yellowHover: '#F7C500',
  grey: '#F4F4F4',
  greyAction: '#dddddd',
  greyDark: '#757575',
  textGrey: '#757575',
  textBlack: '#1A1A1A',
  overlay: '#1A1A1A50',
  white: '#FFF',
};

export default Colors;
