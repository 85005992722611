import React, { memo, useState, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import {
  makeSelectFilterMenuStatus,
  makeSelectLocationFilterDataArray,
  makeSelectLocationFilterId,
} from '../selectors';
import { toggleLocationFilterMenu } from './actions';
import ParentElement from '../../../components/FilterElement/ParentElement';

export const FilterCollapse = ({ onToggleLocationFilterMenu, locationFilterMenuStatus, locationFilterDataArray }) => {
  const [bubbleString, setBubbleString] = useState('');

  useEffect(() => {
    let newBubbleString = '';
    if (locationFilterDataArray.length > 0) {
      newBubbleString = locationFilterDataArray.join(', ');
    }
    if (bubbleString !== newBubbleString) {
      setBubbleString(newBubbleString);
    }
  }, [locationFilterDataArray]);

  /**
   * Main return function.
   */
  return (
    <div onClick={() => onToggleLocationFilterMenu(locationFilterMenuStatus)}>
      <ParentElement title={'Standort'} bubble={bubbleString} status={locationFilterMenuStatus} />
    </div>
  );
};

FilterCollapse.propTypes = {
  locationFilterDataArray: PropTypes.array,
  onToggleLocationFilterMenu: PropTypes.func,
  locationFilterMenuStatus: PropTypes.bool,
};

FilterCollapse.defaultProps = {
  locationFilterDataArray: [],
  onToggleLocationFilterMenu: () => {},
  locationFilterMenuStatus: true,
};

const mapStateToProps = createStructuredSelector({
  locationFilterId: makeSelectLocationFilterId(),
  locationFilterMenuStatus: makeSelectFilterMenuStatus(),
  locationFilterDataArray: makeSelectLocationFilterDataArray(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onToggleLocationFilterMenu: (value) => dispatch(toggleLocationFilterMenu(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterCollapse);
