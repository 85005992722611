import styled from 'styled-components';
import Colors from '../Colors';
import Transitions from '../Transitions';

export const ShowResultsButton = styled.button`
  width: 100%;
  background-color: ${Colors.yellow};
  position: fixed;
  bottom: 20px;
  width: 320px;
  border: none;
  text-align: left;
  padding: 16px 16px 14px 16px;
  cursor: pointer;
  font-size: 18px;
  transition: ${Transitions.all};
  
  &:hover {
    padding-left: 32px;
    background-color: ${Colors.yellowHover};
  }
  
  &:active {
    transform: scale3d(0.95,0.95,1);
  }
}
`;

export default ShowResultsButton;
