import React, { memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import {
  makeSelectFilterMenuStatus,
  makeSelectStructureFilterComplex,
  makeSelectStructureFilterDataArray,
} from '../selectors';
import { toggleStructureFilterMenu } from './actions';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PillYellow, { PillText } from '../../../utils/Components/PillYellow';
import { setStructureFilterComplex, setStructureFilterDataArray, setStructureFilterId } from '../actions';

const key = 'structure';

export const FilterPills = ({
  structureFilterDataArray,
  onSetStructureFilterId,
  onSetStructureFilterDataArray,
  structureFilterComplex,
  onSetStructureFilterComplex,
}) => {
  const handlePillClick = (index) => {
    const updatedStringFilter = [...structureFilterDataArray.slice(index + 1), ...[]];
    onSetStructureFilterComplex([...structureFilterComplex.slice(index + 1), ...[]]);
    onSetStructureFilterDataArray(updatedStringFilter);
    if (updatedStringFilter.length > 0) {
      onSetStructureFilterId(updatedStringFilter[0]);
    } else {
      onSetStructureFilterId('');
    }
  };

  /**
   * Main return function.
   */
  return structureFilterDataArray.map((item, index) => (
    <PillYellow key={`${key}_${item}`} onClick={() => handlePillClick(index)}>
      <PillText>{item}</PillText>
      <FontAwesomeIcon icon={faTimes} />
    </PillYellow>
  ));
};

FilterPills.propTypes = {
  onToggleStructureFilterMenu: PropTypes.func,
  structureFilterMenuStatus: PropTypes.bool,
};

FilterPills.defaultProps = {
  structureFilterDataArray: [],
  onToggleStructureFilterMenu: () => {},
  structureFilterMenuStatus: true,
};

const mapStateToProps = createStructuredSelector({
  structureFilterMenuStatus: makeSelectFilterMenuStatus(),
  structureFilterDataArray: makeSelectStructureFilterDataArray(),
  structureFilterComplex: makeSelectStructureFilterComplex(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onToggleStructureFilterMenu: (value) => dispatch(toggleStructureFilterMenu(value)),
    onSetStructureFilterId: (value) => dispatch(setStructureFilterId(value)),
    onSetStructureFilterDataArray: (value) => dispatch(setStructureFilterDataArray(value)),
    onSetStructureFilterComplex: (value) => dispatch(setStructureFilterComplex(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterPills);
