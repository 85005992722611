import React from 'react';
import PropTypes from 'prop-types';
import BaseApp from './containers/App';

function App({ location, structure }) {
  return (
    <div id="course-app" className="course-app">
      <BaseApp location={location} structure={structure} />
    </div>
  );
}

App.propTypes = {
  location: PropTypes.string,
  structure: PropTypes.string,
};

App.defaultProps = {
  location: '',
  structure: '',
};

export default App;
