export const SET_FILTER_MENU_STATUS = 'ibw/SET_FILTER_MENU_STATUS';
export const SET_SEARCH_STRING = 'ibw/SET_SEARCH_STRING';
export const SET_OVERVIEW_FILTER_PILLS = 'ibw/SET_OVERVIEW_FILTER_PILLS';
export const SET_FILTER_ACTIVE = 'ibw/SET_FILTER_ACTIVE';
export const LOAD_ALL_FILTER_ITEMS = 'ibw/LOAD_ALL_FILTER_ITEMS';
// Structure
export const SET_STRUCTURE_FILTER_CONTENT = 'ibw/SET_STRUCTURE_FILTER_CONTENT';
export const SET_STRUCTURE_FILTER_ID = 'ibw/SET_STRUCTURE_FILTER_ID';
export const SET_STRUCTURE_FILTER_COMPLEX = 'ibw/SET_STRUCTURE_FILTER_COMPLEX';
export const SET_STRUCTURE_FILTER_DATA_ARRAY = 'ibw/SET_STRUCTURE_FILTER_STRING';
export const SET_STRUCTURE_FILTER_READABLE_CONTENT = 'ibw/SET_STRUCTURE_FILTER_READABLE_CONTENT';
// Location
export const SET_LOCATION_FILTER_CONTENT = 'ibw/SET_LOCATION_FILTER_CONTENT';
export const SET_LOCATION_FILTER_ID = 'ibw/SET_LOCATION_FILTER_ID';
export const SET_LOCATION_FILTER_DATA_ARRAY = 'ibw/SET_LOCATION_FILTER_DATA_ARRAY';
export const SET_LOCATION_FILTER_READABLE_CONTENT = 'ibw/SET_LOCATION_FILTER_READABLE_CONTENT';
// Degree
export const SET_DEGREE_FILTER_CONTENT = 'ibw/SET_DEGREE_FILTER_CONTENT';
export const SET_DEGREE_FILTER_ID = 'ibw/SET_DEGREE_FILTER_ID';
export const SET_DEGREE_FILTER_DATA_ARRAY = 'ibw/SET_DEGREE_FILTER_DATA_ARRAY';
export const SET_DEGREE_FILTER_READABLE_CONTENT = 'ibw/SET_DEGREE_FILTER_READABLE_CONTENT';
// Days
export const SET_DAYS_FILTER_CONTENT = 'ibw/SET_DAYS_FILTER_CONTENT';
export const SET_DAYS_FILTER_ID = 'ibw/SET_DAYS_FILTER_ID';
export const SET_DAYS_FILTER_DATA_ARRAY = 'ibw/SET_DAYS_FILTER_DATA_ARRAY';
// Time
export const SET_TIME_FILTER_CONTENT = 'ibw/SET_TIME_FILTER_CONTENT';
export const SET_TIME_FILTER_ID = 'ibw/SET_TIME_FILTER_ID';
export const SET_TIME_FILTER_DATA_ARRAY = 'ibw/SET_TIME_FILTER_DATA_ARRAY';
