import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import styled from 'styled-components';
import { debounce } from 'lodash';
import GlobalStyle from '../../global-styles';
import { loadCourses, setAppisMobile, setPresetLocation, setPresetStructure } from './actions';
import { makeSelectAppIsMobile, makeSelectCourses } from './selectors';
import saga from './saga';
import Search from '../Search';
import CoursesBlock from '../CoursesBlock';
import { useInjectSaga } from '../../utils/injectSaga';
import { useInjectReducer } from '../../utils/injectReducer';
import reducer from './reducer';
import moment from 'moment';
import localization from 'moment/locale/de-ch';
import { APP_MAX_TABLET_WIDTH } from '../../utils/constants';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

const key = 'courses';

const IbwCourseSearch = styled.div`
  width: 100%;
`;

export function App({
  onLoadCourses,
  location,
  degree,
  structure,
  onSetPreSetLocation,
  onSetPreSetStructure,
  onSetAppIsMobile,
  stateAppIsMobile,
}) {
  useInjectSaga({ key, saga });
  useInjectReducer({ key, reducer });
  const { search } = useLocation();
  const [appIsMobile, setAppIsMobile] = useState(window.innerWidth < APP_MAX_TABLET_WIDTH);
  const queryParams = queryString.parse(search);
  const hasInitialSearch = queryParams.search?.length > 0;
  const hasInitialFilter = queryParams.filter?.length > 0;

  useEffect(() => {
    moment.updateLocale('de-ch', localization);
    import('./font');
    // Prevent url parameters to overpower pre-filters.
    if (search.length === 0) {
      if (location !== null && location.length > 0) {
        onSetPreSetLocation(location.split(',').map((item) => item.trim()));
      }
      if (structure !== null && structure.length > 0) {
        onSetPreSetStructure(structure.split(',').map((item) => item.trim()));
      }
      if (degree !== null && degree.length > 0) {
        onSetPreSetDegree(degree.split(',').map((item) => item.trim()));
      }
    }
    if (!hasInitialSearch && !hasInitialFilter) {
      onLoadCourses();
    }
    onSetAppIsMobile(appIsMobile);
    const debouncedHandleResize = debounce(function handleResize() {
      setAppIsMobile(window.innerWidth < APP_MAX_TABLET_WIDTH);
    }, 400);
    window.addEventListener('resize', debouncedHandleResize);
    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, []);

  useEffect(() => {
    if (stateAppIsMobile !== appIsMobile) {
      onSetAppIsMobile(appIsMobile);
    }
  }, [appIsMobile]);

  return (
    <IbwCourseSearch>
      <Search />
      <CoursesBlock />
      <GlobalStyle />
    </IbwCourseSearch>
  );
}

App.propTypes = {
  onLoadCourses: PropTypes.func,
  location: PropTypes.string,
  degree: PropTypes.string,
  structure: PropTypes.string,
  onSetPreSetLocation: PropTypes.func,
  onSetPreSetDegree: PropTypes.func,
  onSetPreSetStructure: PropTypes.func,
  onSetAppIsMobile: PropTypes.func,
  stateAppIsMobile: PropTypes.bool,
};

App.defaultProps = {
  onLoadCourses: () => {},
  location: '',
  degree: '',
  structure: '',
  onSetPreSetLocation: () => {},
  onSetPreSetDegree: () => {},
  onSetPreSetStructure: () => {},
  onSetAppIsMobile: () => {},
  stateAppIsMobile: false,
};

const mapStateToProps = createStructuredSelector({
  courses: makeSelectCourses(),
  stateAppIsMobile: makeSelectAppIsMobile(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onLoadCourses: () => dispatch(loadCourses()),
    onSetPreSetLocation: (value) => dispatch(setPresetLocation(value)),
    onSetPreSetDegree: (value) => dispatch(setPresetDegree(value)),
    onSetPreSetStructure: (value) => dispatch(setPresetStructure(value)),
    onSetAppIsMobile: (value) => dispatch(setAppisMobile(value)),
  };
}

export default compose(connect(mapStateToProps, mapDispatchToProps), memo)(App);

// export default App;
