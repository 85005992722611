import React, { memo, useEffect, useState } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { makeSelectCourses, makeSelectTotalResults } from '../App/selectors';
import Desktop from '../../components/CourseItem/Desktop';
import styled from 'styled-components';
import { orderBy } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortAlt, faSortAlphaUp, faSortAlphaDown } from '@fortawesome/pro-light-svg-icons';
import Colors from '../../utils/Colors';
import ResultCount from '../../utils/Components/ResultCounter';
import SearchStatus from '../SearchStatus';

const OfferWrapper = styled.div`
  max-width: 1136px;
  margin: 0 auto;
  padding: 0 28px;

  table thead span {
    padding-right: 20px;
  }
  @media (min-width: 1200px) {
    padding: 0;
  }
`;

const SortHeader = styled.td`
  padding: 10px 0 10px 32px;
  margin: 0 15px;
  cursor: pointer;

  &:hover {
    background-color: ${Colors.grey};
  }
  &:active {
    background-color: ${Colors.greyAction};
  }
`;

const SortHeaderWide = styled(SortHeader)`
  padding: 10px 0 10px 8px;
  margin: 0;
`;

export const DesktopDisplay = ({ courses, totalResults }) => {
  const [currentCourses, setCurrentCourses] = useState(courses);
  const [activeSort, setActiveSort] = useState({
    id: '',
    direction: 'asc',
  });
  useEffect(() => {
    setCurrentCourses(courses);
  }, [courses]);

  const Block = () => currentCourses.map((course) => <Desktop course={course} key={course.id} />);

  const sortFilter = (filter) => {
    let direction = activeSort.direction === 'asc' ? 'desc' : 'asc';
    direction = filter !== activeSort.id ? 'asc' : direction;
    setCurrentCourses(orderBy(currentCourses, [filter], [direction]));
    setActiveSort({
      id: filter,
      direction: direction,
    });
  };

  const SortArrows = ({ sort }) => {
    if (sort === activeSort.id) {
      if (activeSort.direction === 'asc') {
        return <FontAwesomeIcon icon={faSortAlphaDown} />;
      }
      return <FontAwesomeIcon icon={faSortAlphaUp} />;
    }
    return <FontAwesomeIcon icon={faSortAlt} />;
  };

  SortArrows.propTypes = {
    sort: PropTypes.string,
  };
  SortArrows.defaultProps = {
    sort: '',
  };

  return (
    <OfferWrapper>
      <ResultCount>{`${totalResults} Angebot${totalResults !== 1 ? 'e' : ''} gefunden`}</ResultCount>
      <table style={{ borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <SortHeaderWide onClick={() => sortFilter('name')}>
              <span>Angebot</span>
              <SortArrows sort={'name'} />
            </SortHeaderWide>
            <SortHeader onClick={() => sortFilter('location_names')}>
              <span>Standort</span>
              <SortArrows sort={'location_names'} />
            </SortHeader>
            <SortHeader onClick={() => sortFilter('field_course_start_end_date')}>
              <span>Startdatum</span>
              <SortArrows sort={'field_course_start_end_date'} />
            </SortHeader>
            <SortHeader>
              <span>Kosten</span>
            </SortHeader>
            <SortHeader>
              <span>Wochentag</span>
            </SortHeader>
          </tr>
        </thead>
        <tbody>
          <Block />
        </tbody>
      </table>
      <SearchStatus />
    </OfferWrapper>
  );
};

DesktopDisplay.propTypes = {
  courses: PropTypes.array,
  totalResults: PropTypes.number,
};
DesktopDisplay.defaultProps = {
  courses: [],
  totalResults: 0,
};

const mapStateToProps = createStructuredSelector({
  courses: makeSelectCourses(),
  totalResults: makeSelectTotalResults(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, memo)(DesktopDisplay);
