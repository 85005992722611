import React, { memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import {
  makeSelectFilterMenuStatus,
  makeSelectDegreeFilterDataArray,
  makeSelectDegreeFilterId,
} from '../selectors';
import { toggleDegreeFilterMenu } from './actions';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PillYellow, { PillText } from '../../../utils/Components/PillYellow';
import { setDegreeFilterDataArray, setDegreeFilterId } from '../actions';

const key = 'degree';

export const FilterPills = ({
  degreeFilterId,
  degreeFilterDataArray,
  onSetDegreeFilterId,
  onSetDegreeFilterDataArray,
}) => {
  if (degreeFilterDataArray.length === 0) {
    return <></>;
  }

  const handlePillClick = (index) => {
    onSetDegreeFilterId([...degreeFilterId.slice(0, index), ...degreeFilterId.slice(index + 1)]);
    onSetDegreeFilterDataArray([
      ...degreeFilterDataArray.slice(0, index),
      ...degreeFilterDataArray.slice(index + 1),
    ]);
  };

  /**
   * Main return function.
   */
  return degreeFilterDataArray.map((item, index) => (
    <PillYellow key={`${key}_${item}`} onClick={() => handlePillClick(index)}>
      <PillText>{item}</PillText>
      <FontAwesomeIcon icon={faTimes} />
    </PillYellow>
  ));
};

FilterPills.propTypes = {
  degreeFilterId: PropTypes.array,
  onToggleDegreeFilterMenu: PropTypes.func,
  degreeFilterMenuStatus: PropTypes.bool,
};

FilterPills.defaultProps = {
  degreeFilterDataArray: [],
  onToggleDegreeFilterMenu: () => {},
  degreeFilterMenuStatus: true,
};

const mapStateToProps = createStructuredSelector({
  degreeFilterId: makeSelectDegreeFilterId(),
  degreeFilterMenuStatus: makeSelectFilterMenuStatus(),
  degreeFilterDataArray: makeSelectDegreeFilterDataArray(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onToggleDegreeFilterMenu: (value) => dispatch(toggleDegreeFilterMenu(value)),
    onSetDegreeFilterId: (value) => dispatch(setDegreeFilterId(value)),
    onSetDegreeFilterDataArray: (value) => dispatch(setDegreeFilterDataArray(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterPills);
