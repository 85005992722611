import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Colors from '../../utils/Colors';
import Transitions from '../../utils/Transitions';

const FilterElementRow = styled.div`
  padding: 14px 16px 14px 5px;
  display: flex;
  position: relative;
  width: 100%;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  transition: ${Transitions.all};
  &:hover {
    background-color: ${Colors.grey};
  }

  &:active {
    background-color: ${Colors.greyAction};
    .title {
      transition: ${Transitions.all};
      transform: scale3d(0.97, 0.97, 1);
    }
  }
`;

const Title = styled.div`
  display: block;
  float: left;
  flex-grow: 1;
  text-align: left;
`;

const SignetRadio = styled.div`
  display: flex;
  position: relative;
  border: 1px solid ${Colors.textBlack};
  border-radius: 50%;
  height: 20px;
  width: 20px;
  float: left;
  margin: -2px 10px 0 0;
  justify-content: center;
  align-items: center;

  &.active {
    background-color: ${Colors.yellow};
    opacity: 0.5;
    filter: grayscale(100%);

    &:after {
      content: '';
      display: block;
      position: absolute;
      width: 10px;
      height: 10px;
      background-color: ${Colors.textBlack};
      border-radius: 50%;
    }

    &.pos0 {
      opacity: 1;
      filter: none;
    }
  }
`;

export const FilterElement = ({ title, status, position }) => (
  <FilterElementRow>
    <div>
      <SignetRadio className={status ? `signet active pos${position}` : 'signet'} />
    </div>
    <Title className={'title'}>{title}</Title>
  </FilterElementRow>
);

FilterElement.propTypes = {
  title: PropTypes.string,
  bubble: PropTypes.string,
  status: PropTypes.bool,
  position: PropTypes.number,
};

FilterElement.defaultProps = {
  title: '',
  bubble: '',
  status: false,
  position: 0,
};

export default FilterElement;
