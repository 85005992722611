import React, { memo, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import { makeSelectFilterActive } from '../selectors';
import LocationsFilterPills from '../SearchLocations/FilterPills';
import DegreeFilterPills from '../SearchDegree/FilterPills';
import StructureFilterPills from '../SearchFilters/FilterPills';
import DaysFilterPills from '../SearchDays/FilterPills';
import TimeFilterPills from '../SearchTime/FilterPills';
import { faChevronDown } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import Colors from '../../../utils/Colors';
import Transitions from '../../../utils/Transitions';
import { APP_MAX_TABLET_WIDTH } from '../../../utils/constants';
import { makeSelectAppIsMobile } from '../../App/selectors';

const FilterPillWrapper = styled.div`
  width: 100%;
  display: block;
  overflow: hidden;
  transition: ${Transitions.all};
`;

const FilterPillInnerWrapper = styled.div`
  width: 100%;
  display: block;
`;

const FilterPillOuterWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  @media (min-width: ${APP_MAX_TABLET_WIDTH}px) {
    margin: 25px 0 0;
  }
`;

const Expander = styled.div`
  display: none;
  width: 100%;
  border-top: 1px solid ${Colors.greyAction};
  text-align: center;
  margin-top: 10px;
  padding-top: 5px;
  color: ${Colors.greyDark};
  &.visible {
    display: block;
  }

  .svg-inline--fa {
    transition: ${Transitions.all};
    font-size: 22px;
  }

  &.expanded .svg-inline--fa {
    transform: rotate(180deg);
  }
`;

export const MainViewFilterPills = ({ filterActive, appIsMobile }) => {
  const [height, setHeight] = useState(0);
  const [expanded, setExpanded] = useState(false);

  const wrapperHeight = useRef(null);

  useLayoutEffect(() => {
    setHeight(wrapperHeight.current.clientHeight);
  }, [filterActive]);

  if (filterActive === 0) {
    return <></>;
  }

  const getHeight = () => {
    if (appIsMobile) {
      return { height: expanded ? height : '54px' };
    }
    return { height: 'auto' };
  };

  const RenderedExpander = () => {
    if (!appIsMobile) {
      return <></>;
    }

    return (
      <Expander
        className={`${height > 57 ? 'visible' : ''} ${expanded ? 'expanded' : ''} `}
        onClick={() => setExpanded(!expanded)}
      >
        <FontAwesomeIcon icon={faChevronDown} />
      </Expander>
    );
  };

  return (
    <FilterPillOuterWrapper>
      <FilterPillWrapper style={getHeight()}>
        <FilterPillInnerWrapper ref={wrapperHeight}>
          <LocationsFilterPills />
          <StructureFilterPills />
          <DegreeFilterPills />
          <DaysFilterPills />
          <TimeFilterPills />
        </FilterPillInnerWrapper>
      </FilterPillWrapper>
      <RenderedExpander />
    </FilterPillOuterWrapper>
  );
};

MainViewFilterPills.propTypes = {
  filterActive: PropTypes.number,
  appIsMobile: PropTypes.bool,
};

MainViewFilterPills.defaultProps = {
  filterActive: 0,
  appIsMobile: false,
};

const mapStateToProps = createStructuredSelector({
  filterActive: makeSelectFilterActive(),
  appIsMobile: makeSelectAppIsMobile(),
});

export function mapDispatchToProps(dispatch) {
  return {};
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(MainViewFilterPills);
