import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { makeSelectFilterActive, makeSelectLocationFilterContent } from '../selectors';
import { makeSelectPreSetLocation } from '../../App/selectors';
import { setLocationFilterDataArray, setLocationFilterId } from '../actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import initialStateConstants from '../initialStateConstants';

export const LocationPreFilter = ({
  locationItems,
  preSetLocation,
  onSetLocationFilterId,
  onSetLocationFilterDataArray,
}) => {
  const appHistory = useHistory();
  const { search } = useLocation();
  const [queryParams, setQueryParams] = useState(queryString.parse(search)?.location ?? '');

  /**
   * @implements useEffect()
   *
   * Checks if filter and pre-set filtering should be run once (new) filterable items
   * become available.
   */
  useEffect(() => {
    if (preSetLocation.length > 0 || queryParams.length > 0) {
      preFilter(queryParams);
    }
  }, [locationItems]);

  /**
   * @implements useEffect()
   *
   * Checks if a query parameter for this filter has changed and
   * kicks-off updates.
   */
  useEffect(() => {
    let newQueryString = queryString.parse(search)?.location ?? '';
    if (newQueryString === queryParams) {
      return null;
    }
    if (appHistory.action === 'POP') {
      preFilter(newQueryString);
    }
    setQueryParams(newQueryString);
  }, [search]);

  const preFilter = (newQueryString) => {
    if (locationItems.length === 0) {
      return null;
    }
    let filterItems = [];
    if (preSetLocation.length > 0) {
      filterItems = preSetLocation;
    }
    if (newQueryString.length > 0) {
      filterItems = newQueryString.split('_');
    }
    if (filterItems?.length > 0) {
      onSetLocationFilterId(filterItems);
      const locationStrings = filterItems.map((currentItem) => {
        const result = locationItems.find((item) => Object.keys(item)[0] === currentItem);
        return result[Object.keys(result)].name;
      });
      onSetLocationFilterDataArray(locationStrings);
    } else {
      resetFilter();
    }
  };

  const resetFilter = () => {
    onSetLocationFilterId(initialStateConstants.locationFilterId);
    onSetLocationFilterDataArray(initialStateConstants.locationFilterDataArray);
  };

  return <></>;
};

LocationPreFilter.propTypes = {
  locationItems: PropTypes.array,
  onSetLocationFilterId: PropTypes.func,
  onSetLocationFilterDataArray: PropTypes.func,
  preSetLocation: PropTypes.array,
  filterActive: PropTypes.number,
};

LocationPreFilter.defaultProps = {
  locationItems: [],
  onSetLocationFilterId: () => {},
  onSetLocationFilterDataArray: () => {},
  preSetLocation: [],
  filterActive: 0,
};

const mapStateToProps = createStructuredSelector({
  locationItems: makeSelectLocationFilterContent(),
  preSetLocation: makeSelectPreSetLocation(),
  filterActive: makeSelectFilterActive(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onSetLocationFilterId: (value) => dispatch(setLocationFilterId(value)),
    onSetLocationFilterDataArray: (value) => dispatch(setLocationFilterDataArray(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(LocationPreFilter);
