import React, { memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { makeSelectStructureFilterContent } from '../selectors';
import ItemGroup from './ItemGroup';
import FilterCollapse from './FilterCollapse';
import { useInjectReducer } from '../../../utils/injectReducer';
import reducer from './reducer';
import { makeSelectStructureFilterMenuStatus } from './selectors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons';
import { toggleStructureFilterMenu } from './actions';
import { FilterHeaderBlock } from '../FilterOverlay/FilterHeader';
import {
  setFilterMenuStatus,
  setStructureFilterComplex,
  setStructureFilterDataArray,
  setStructureFilterId,
} from '../actions';
import FilterOverlayBlock from '../../../utils/Components/FilterOverlayBlock';
import CollapsedList from '../../../utils/Components/CollapsedList';
import LocalReset from '../../../utils/Components/LocalReset';
import CurrentFilterResetBlock from '../../../components/CurrentFilterResetBlock';
import ShowResultsButton from '../../../utils/Components/ShowResultsButton';
import { makeSelectPreSetStructure } from '../../App/selectors';
import FilterListWrapper from '../../../utils/Components/FilterListWrapper';

const key = 'filter';

export const SearchFilters = ({
  structureItems,
  filterStructureMenuStatus,
  onToggleStructureFilterMenu,
  onSetStructureFilterId,
  onSetStructureFilterComplex,
  onSetStructureFilterDataArray,
  onSetFilterMenuStatus,
}) => {
  useInjectReducer({ key, reducer });

  const Items = () => {
    if (structureItems.length === 0) {
      return <></>;
    }
    return Object.entries(structureItems).map((item) => <ItemGroup key={item[1].id} item={item[1]} />);
  };

  const handleResultsClick = () => {
    onToggleStructureFilterMenu(false);
    onSetFilterMenuStatus(false);
  };

  const handleLocalResetClick = () => {
    onSetStructureFilterId('');
    onSetStructureFilterComplex([]);
    onSetStructureFilterDataArray([]);
  };

  const CurrentFilterReset = () => (
    <LocalReset onClick={() => handleLocalResetClick()}>
      <CurrentFilterResetBlock />
    </LocalReset>
  );

  /**
   * Main return function.
   */
  return (
    <CollapsedList className={filterStructureMenuStatus ? 'open' : ''}>
      <FilterCollapse />
      <FilterOverlayBlock className={'item-list'}>
        <FilterHeaderBlock>
          <span>THEMENBEREICH</span>
          <FontAwesomeIcon icon={faArrowLeft} onClick={() => onToggleStructureFilterMenu()} />
        </FilterHeaderBlock>
        <CurrentFilterReset />
        <FilterListWrapper>
          <Items />
        </FilterListWrapper>
        <ShowResultsButton onClick={() => handleResultsClick()}>Resultate anzeigen</ShowResultsButton>
      </FilterOverlayBlock>
    </CollapsedList>
  );
};

SearchFilters.propTypes = {
  structureItems: PropTypes.object,
  filterStructureMenuStatus: PropTypes.bool,
  onToggleStructureFilterMenu: PropTypes.func,
  onSetStructureFilterId: PropTypes.func,
  onSetStructureFilterComplex: PropTypes.func,
  onSetStructureFilterDataArray: PropTypes.func,
  onSetFilterMenuStatus: PropTypes.func,
  preSetStructure: PropTypes.array,
  structureComplex: PropTypes.array,
};

SearchFilters.defaultProps = {
  structureItems: {},
  filterStructureMenuStatus: false,
  onToggleStructureFilterMenu: () => {},
  onSetStructureFilterId: () => {},
  onSetStructureFilterComplex: () => {},
  onSetStructureFilterDataArray: () => {},
  onSetFilterMenuStatus: () => {},
  preSetStructure: [],
  structureComplex: [],
};

const mapStateToProps = createStructuredSelector({
  structureItems: makeSelectStructureFilterContent(),
  filterStructureMenuStatus: makeSelectStructureFilterMenuStatus(),
  preSetStructure: makeSelectPreSetStructure(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onToggleStructureFilterMenu: () => dispatch(toggleStructureFilterMenu(false)),
    onSetStructureFilterId: (value) => dispatch(setStructureFilterId(value)),
    onSetStructureFilterComplex: (value) => dispatch(setStructureFilterComplex(value)),
    onSetStructureFilterDataArray: (value) => dispatch(setStructureFilterDataArray(value)),
    onSetFilterMenuStatus: (value) => dispatch(setFilterMenuStatus(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(SearchFilters);
