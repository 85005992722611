import styled from 'styled-components';
import Colors from '../Colors';

export const Pill = styled.div`
  box-sizing: border-box;
  background-color: ${Colors.grey};
  min-height: 24px;
  max-height 24px;
  font-size: 12px;
  line-height: 12px;
  border-radius: 12px;
  display: inline-block;
  padding: 6px 8px 5px;
  margin-right: 15px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 113px;

  @media (min-width: 375px) {
    max-width: 155px;
  }
`;

export default Pill;
