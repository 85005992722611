import {
  LOAD_COURSES,
  SET_APP_IS_LOADING,
  SET_APP_IS_MOBILE,
  SET_COURSES,
  SET_FACETS,
  SET_PRESET_LOCATION,
  SET_PRESET_DEGREE,
  SET_PRESET_STRUCTURE,
  SET_TOTAL,
} from './constants';

export function loadCourses() {
  return {
    type: LOAD_COURSES,
  };
}

export function setCourses(courses) {
  return {
    type: SET_COURSES,
    courses,
  };
}

export function setTotalItemCount(value) {
  return {
    type: SET_TOTAL,
    value,
  };
}

export function setFacetInformation(value) {
  return {
    type: SET_FACETS,
    value,
  };
}

export function setPresetLocation(value) {
  return {
    type: SET_PRESET_LOCATION,
    value,
  };
}

export function setPresetDegree(value) {
  return {
    type: SET_PRESET_DEGREE,
    value,
  };
}

export function setPresetStructure(value) {
  return {
    type: SET_PRESET_STRUCTURE,
    value,
  };
}

export function setAppisMobile(value) {
  return {
    type: SET_APP_IS_MOBILE,
    value,
  };
}

export function setAppIsLoading(value) {
  return {
    type: SET_APP_IS_LOADING,
    value,
  };
}
