import {
  SET_FILTER_MENU_STATUS,
  SET_LOCATION_FILTER_CONTENT,
  SET_LOCATION_FILTER_ID,
  SET_LOCATION_FILTER_READABLE_CONTENT,
  SET_LOCATION_FILTER_DATA_ARRAY,
  SET_SEARCH_STRING,
  SET_STRUCTURE_FILTER_COMPLEX,
  SET_STRUCTURE_FILTER_CONTENT,
  SET_STRUCTURE_FILTER_ID,
  SET_STRUCTURE_FILTER_DATA_ARRAY,
  SET_DAYS_FILTER_ID,
  SET_DAYS_FILTER_DATA_ARRAY,
  SET_DAYS_FILTER_CONTENT,
  SET_TIME_FILTER_ID,
  SET_TIME_FILTER_DATA_ARRAY,
  SET_TIME_FILTER_CONTENT,
  SET_FILTER_ACTIVE,
  LOAD_ALL_FILTER_ITEMS,
  SET_STRUCTURE_FILTER_READABLE_CONTENT,
  SET_DEGREE_FILTER_CONTENT,
  SET_DEGREE_FILTER_ID,
  SET_DEGREE_FILTER_READABLE_CONTENT,
  SET_DEGREE_FILTER_DATA_ARRAY,

} from './constants';

export function setFilterMenuStatus(value) {
  return {
    type: SET_FILTER_MENU_STATUS,
    value,
  };
}
export function setSearchString(value) {
  return {
    type: SET_SEARCH_STRING,
    value,
  };
}
export function setFilterActive(value) {
  return {
    type: SET_FILTER_ACTIVE,
    value,
  };
}
export function loadAllFilterItems() {
  return {
    type: LOAD_ALL_FILTER_ITEMS,
  };
}

// Structure
export function setStructureFilterContent(value) {
  return {
    type: SET_STRUCTURE_FILTER_CONTENT,
    value,
  };
}
export function setStructureFilterComplex(value) {
  return {
    type: SET_STRUCTURE_FILTER_COMPLEX,
    value,
  };
}
export function setStructureFilterId(value) {
  return {
    type: SET_STRUCTURE_FILTER_ID,
    value,
  };
}
export function setStructureFilterDataArray(value) {
  return {
    type: SET_STRUCTURE_FILTER_DATA_ARRAY,
    value,
  };
}
export function setStructureFilterReadableContent(value) {
  return {
    type: SET_STRUCTURE_FILTER_READABLE_CONTENT,
    value,
  };
}

// Locations
export function setLocationFilterId(value) {
  return {
    type: SET_LOCATION_FILTER_ID,
    value,
  };
}
export function setLocationFilterDataArray(value) {
  return {
    type: SET_LOCATION_FILTER_DATA_ARRAY,
    value,
  };
}
export function setLocationFilterContent(value) {
  return {
    type: SET_LOCATION_FILTER_CONTENT,
    value,
  };
}
export function setLocationFilterReadableContent(value) {
  return {
    type: SET_LOCATION_FILTER_READABLE_CONTENT,
    value,
  };
}

// Degrees
export function setDegreeFilterId(value) {
  return {
    type: SET_DEGREE_FILTER_ID,
    value,
  };
}
export function setDegreeFilterDataArray(value) {
  return {
    type: SET_DEGREE_FILTER_DATA_ARRAY,
    value,
  };
}
export function setDegreeFilterContent(value) {
  return {
    type: SET_DEGREE_FILTER_CONTENT,
    value,
  };
}
export function setDegreeFilterReadableContent(value) {
  return {
    type: SET_DEGREE_FILTER_READABLE_CONTENT,
    value,
  };
}

// Days
export function setDaysFilterId(value) {
  return {
    type: SET_DAYS_FILTER_ID,
    value,
  };
}
export function setDaysFilterDataArray(value) {
  return {
    type: SET_DAYS_FILTER_DATA_ARRAY,
    value,
  };
}
export function setDaysFilterContent(value) {
  return {
    type: SET_DAYS_FILTER_CONTENT,
    value,
  };
}

// Time
export function setTimeFilterId(value) {
  return {
    type: SET_TIME_FILTER_ID,
    value,
  };
}
export function setTimeFilterDataArray(value) {
  return {
    type: SET_TIME_FILTER_DATA_ARRAY,
    value,
  };
}
export function setTimeFilterContent(value) {
  return {
    type: SET_TIME_FILTER_CONTENT,
    value,
  };
}
