import React, { memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { makeSelectFilterMenuStatus, makeSelectTimeFilterDataArray, makeSelectTimeFilterId } from '../selectors';
import { toggleTimeFilterMenu } from './actions';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import PillYellow, { PillText } from '../../../utils/Components/PillYellow';
import { setTimeFilterDataArray, setTimeFilterId } from '../actions';

const key = 'time';

export const FilterPills = ({ timeFilterId, timeFilterDataArray, onSetTimeFilterId, onSetTimeFilterDataArray }) => {
  if (timeFilterDataArray.length === 0) {
    return <></>;
  }

  const handlePillClick = (index) => {
    onSetTimeFilterId([...timeFilterId.slice(0, index), ...timeFilterId.slice(index + 1)]);
    onSetTimeFilterDataArray([...timeFilterDataArray.slice(0, index), ...timeFilterDataArray.slice(index + 1)]);
  };

  /**
   * Main return function.
   */
  return timeFilterDataArray.map((item, index) => (
    <PillYellow key={`${key}_${item}`} onClick={() => handlePillClick(index)}>
      <PillText>{item}</PillText>
      <FontAwesomeIcon icon={faTimes} />
    </PillYellow>
  ));
};

FilterPills.propTypes = {
  timeFilterId: PropTypes.array,
  onToggleTimeFilterMenu: PropTypes.func,
  timeFilterMenuStatus: PropTypes.bool,
};

FilterPills.defaultProps = {
  timeFilterDataArray: [],
  onToggleTimeFilterMenu: () => {},
  timeFilterMenuStatus: true,
};

const mapStateToProps = createStructuredSelector({
  timeFilterId: makeSelectTimeFilterId(),
  timeFilterMenuStatus: makeSelectFilterMenuStatus(),
  timeFilterDataArray: makeSelectTimeFilterDataArray(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onToggleTimeFilterMenu: (value) => dispatch(toggleTimeFilterMenu(value)),
    onSetTimeFilterId: (value) => dispatch(setTimeFilterId(value)),
    onSetTimeFilterDataArray: (value) => dispatch(setTimeFilterDataArray(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(FilterPills);
