import produce from 'immer';
import { TOGGLE_DEGREE_FILTER_MENU } from './constants';

export const initialState = {
  degreeFilterMenuStatus: false,
};

/* eslint-disable default-case, no-param-reassign */
const degreeReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TOGGLE_DEGREE_FILTER_MENU:
        draft.degreeFilterMenuStatus = action.value;
        break;
      default:
        break;
    }
  });

export default degreeReducer;
