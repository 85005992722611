import produce from 'immer';

import {
  SET_FILTER_ACTIVE,
  SET_FILTER_MENU_STATUS,
  SET_LOCATION_FILTER_CONTENT,
  SET_LOCATION_FILTER_ID,
  SET_LOCATION_FILTER_READABLE_CONTENT,
  SET_LOCATION_FILTER_DATA_ARRAY,
  SET_DEGREE_FILTER_CONTENT,
  SET_DEGREE_FILTER_ID,
  SET_DEGREE_FILTER_READABLE_CONTENT,
  SET_DEGREE_FILTER_DATA_ARRAY,
  SET_STRUCTURE_FILTER_COMPLEX,
  SET_STRUCTURE_FILTER_CONTENT,
  SET_STRUCTURE_FILTER_ID,
  SET_STRUCTURE_FILTER_DATA_ARRAY,
  SET_STRUCTURE_FILTER_READABLE_CONTENT,
  SET_DAYS_FILTER_ID,
  SET_DAYS_FILTER_CONTENT,
  SET_DAYS_FILTER_DATA_ARRAY,
  SET_TIME_FILTER_ID,
  SET_TIME_FILTER_CONTENT,
  SET_TIME_FILTER_DATA_ARRAY,
  SET_SEARCH_STRING
} from './constants';
import initialStateConstants from './initialStateConstants';

export const initialState = {
  searchString: initialStateConstants.searchString,
  filterActive: JSON.parse(localStorage.getItem('filter_active')) ?? initialStateConstants.filterActive,
  filterMenuStatus: initialStateConstants.filterMenuStatus,
  overviewFilterPills: initialStateConstants.overviewFilterPills,
  structure: initialStateConstants.structure,
  structureFilterId: JSON.parse(localStorage.getItem('structure_filter_id')) ?? initialStateConstants.structureFilterId,
  structureFilterDataArray:
    JSON.parse(localStorage.getItem('structure_filter_data_array')) ?? initialStateConstants.structureFilterDataArray,
  structureFilterComplex:
    JSON.parse(localStorage.getItem('structure_filter_complex')) ?? initialStateConstants.structureFilterComplex,
  structureFilterReadable: initialStateConstants.structureFilterReadable,
  locationFilterId: initialStateConstants.locationFilterId,
  locationFilterDataArray: initialStateConstants.locationFilterDataArray,
  location: initialStateConstants.location,
  locationFilterReadable: initialStateConstants.locationFilterReadable,
  degreeFilterId: initialStateConstants.degreeFilterId,
  degreeFilterDataArray: initialStateConstants.degreeFilterDataArray,
  degree: initialStateConstants.degree,
  degreeFilterReadable: initialStateConstants.degreeFilterReadable,
  days: initialStateConstants.days,
  daysFilterId: initialStateConstants.daysFilterId,
  daysFilterDataArray: initialStateConstants.daysFilterDataArray,
  time: initialStateConstants.time,
  timeFilterId: initialStateConstants.timeFilterId,
  timeFilterDataArray: initialStateConstants.timeFilterDataArray,
};

/* eslint-disable default-case, no-param-reassign */
const searchReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case SET_SEARCH_STRING:
        draft.searchString = action.value;
        break;
      case SET_FILTER_MENU_STATUS:
        draft.filterMenuStatus = action.value;
        break;
      case SET_FILTER_ACTIVE:
        draft.filterActive = action.value;
        break;
      // Structure
      case SET_STRUCTURE_FILTER_CONTENT:
        draft.structure = action.value;
        break;
      case SET_STRUCTURE_FILTER_ID:
        draft.structureFilterId = action.value;
        break;
      case SET_STRUCTURE_FILTER_COMPLEX:
        draft.structureFilterComplex = action.value;
        break;
      case SET_STRUCTURE_FILTER_DATA_ARRAY:
        draft.structureFilterDataArray = action.value;
        break;
      case SET_STRUCTURE_FILTER_READABLE_CONTENT:
        draft.structureFilterReadable = action.value;
        break;
      // Locations
      case SET_LOCATION_FILTER_ID:
        draft.locationFilterId = action.value;
        break;
      case SET_LOCATION_FILTER_CONTENT:
        draft.location = action.value;
        break;
      case SET_LOCATION_FILTER_DATA_ARRAY:
        draft.locationFilterDataArray = action.value;
        break;
      case SET_LOCATION_FILTER_READABLE_CONTENT:
        draft.locationFilterReadable = action.value;
        break;
      // Degree
      case SET_DEGREE_FILTER_ID:
        draft.degreeFilterId = action.value;
        break;
      case SET_DEGREE_FILTER_CONTENT:
        draft.degree = action.value;
        break;
      case SET_DEGREE_FILTER_DATA_ARRAY:
        draft.degreeFilterDataArray = action.value;
        break;
      case SET_DEGREE_FILTER_READABLE_CONTENT:
        draft.degreeFilterReadable = action.value;
        break;
      // Days
      case SET_DAYS_FILTER_ID:
        draft.daysFilterId = action.value;
        break;
      case SET_DAYS_FILTER_CONTENT:
        draft.days = action.value;
        break;
      case SET_DAYS_FILTER_DATA_ARRAY:
        draft.daysFilterDataArray = action.value;
        break;
      case SET_TIME_FILTER_ID:
        draft.timeFilterId = action.value;
        break;
      case SET_TIME_FILTER_CONTENT:
        draft.time = action.value;
        break;
      case SET_TIME_FILTER_DATA_ARRAY:
        draft.timeFilterDataArray = action.value;
        break;
      default:
        break;
    }
  });

export default searchReducer;
