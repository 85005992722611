import React, { memo, useEffect } from 'react';
import { createStructuredSelector } from 'reselect';
import { makeSelectTimeFilterContent, makeSelectTimeFilterDataArray, makeSelectTimeFilterId } from '../selectors';
import { loadCourses } from '../../App/actions';
import { setTimeFilterDataArray, setTimeFilterId } from '../actions';
import { toggleTimeFilterMenu } from './actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import FilterElementRadio from '../../../components/FilterElement/FilterElementRadio';

export const Items = ({
  timeItems,
  onSetTimeFilterId,
  onLoadCourses,
  timeFilterId,
  timeFilterDataArray,
  onSetTimeFilterDataArray,
}) => {
  useEffect(() => {
    onLoadCourses();
  }, [timeFilterId]);

  const toggleFilterIds = (item) => {
    const itemIndex = timeFilterId.indexOf(item.id);
    if (itemIndex >= 0) {
      onSetTimeFilterId([...timeFilterId.slice(0, itemIndex), ...timeFilterId.slice(itemIndex + 1)]);
      onSetTimeFilterDataArray([
        ...timeFilterDataArray.slice(0, itemIndex),
        ...timeFilterDataArray.slice(itemIndex + 1),
      ]);
    } else {
      onSetTimeFilterId([item.id, ...timeFilterId]);
      onSetTimeFilterDataArray([item.name, ...timeFilterDataArray]);
    }
  };

  if (timeItems.length === 0) {
    return <>Time</>;
  }

  return timeItems.map((entry) => {
    const item = Object.entries(entry)[0];
    return (
      <div key={item[1].id} onClick={() => toggleFilterIds(item[1])}>
        <FilterElementRadio
          title={item[1].name}
          status={timeFilterId.indexOf(item[1].id) >= 0}
          bubble={''}
          position={0}
        />
      </div>
    );
  });
};

Items.propTypes = {
  onLoadCourses: PropTypes.func,
  timeItems: PropTypes.array,
  onSetTimeFilterId: PropTypes.func,
  timeFilterDataArray: PropTypes.array,
};

Items.defaultProps = {
  onLoadCourses: () => {},
  timeItems: [],
  timeFilterDataArray: [],
  onSetTimeFilterId: () => {},
};

const mapStateToProps = createStructuredSelector({
  timeFilterId: makeSelectTimeFilterId(),
  timeItems: makeSelectTimeFilterContent(),
  timeFilterDataArray: makeSelectTimeFilterDataArray(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onLoadCourses: () => dispatch(loadCourses()),
    onSetTimeFilterId: (value) => dispatch(setTimeFilterId(value)),
    onToggleTimeFilterMenu: (value) => dispatch(toggleTimeFilterMenu(value)),
    onSetTimeFilterDataArray: (value) => dispatch(setTimeFilterDataArray(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(Items);
