import produce from 'immer';
import { TOGGLE_DAYS_FILTER_MENU } from './constants';

export const initialState = {
  daysFilterMenuStatus: false,
};

/* eslint-disable default-case, no-param-reassign */
const daysReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case TOGGLE_DAYS_FILTER_MENU:
        draft.daysFilterMenuStatus = action.value;
        break;
      default:
        break;
    }
  });

export default daysReducer;
