export const initialStateConstants = {
  searchString: '',
  filterActive: 0,
  filterMenuStatus: false,
  overviewFilterPills: [],
  structure: {},
  structureFilterId: '',
  structureFilterDataArray: [],
  structureFilterComplex: [],
  structureFilterReadable: {},
  locationFilterId: [],
  locationFilterDataArray: [],
  locationFilterReadable: {},
  location: [],
  degreeFilterId: [],
  degreeFilterDataArray: [],
  degreeFilterReadable: {},
  degree: [],
  days: [],
  daysFilterId: [],
  daysFilterDataArray: [],
  time: [],
  timeFilterId: [],
  timeFilterDataArray: [],
};

export default initialStateConstants;
