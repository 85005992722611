import styled from 'styled-components';
import Transitions from '../Transitions';

const CollapsedList = styled.div`
  transition: ${Transitions.all};
  max-height: 50px;
  overflow: hidden;

  .ReactCollapse--collapse {
    margin-left: 15px;
  }

  .item-list {
    z-index: 50;
    transition: ${Transitions.all};
    display: block;
    transform: translate3d(-120%, 0, 0);
  }

  &.open {
    .item-list {
      display: block;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      transform: none;
    }
  }
`;

export default CollapsedList;
