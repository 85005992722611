import React, { memo } from 'react';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import { makeSelectAppIsLoading, makeSelectTotalResults } from '../App/selectors';
import styled from 'styled-components';
import { MOBILE_PADDING_LR } from '../../utils/constants';

const StatusWrapper = styled.div`
  display: flex;
  font-size: 1.55rem;
  margin: 3rem auto;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  margin: ${MOBILE_PADDING_LR};

  @media (min-width: 700px) {
    justify-content: flex-start;
    text-align: left;
    flex-wrap: nowrap;
  }
`;

const SearchStatusIcon = styled.div`
  width: 125px;
  height: 125px;
  overflow: hidden;
  background-image: url(/themes/custom/ibw/images/ibw_icons.svg);
  background-size: 250px;
  background-repeat: no-repeat;
  @media (min-width: 700px) {
    margin-right: 3rem;
  }
`;

const SearchInProgressIcon = styled(SearchStatusIcon)`
  background-position-y: -125px;
`;

const NoItemsFoundIcon = styled(SearchStatusIcon)`
  background-position-y: -1125px;
`;

const SearchStatus = ({ appIsLoading, totalResults }) => {
  if (appIsLoading) {
    return (
      <StatusWrapper>
        <SearchInProgressIcon />
        <span>Angebote werden geladen.</span>
      </StatusWrapper>
    );
  }

  if (totalResults === 0) {
    return (
      <StatusWrapper>
        <NoItemsFoundIcon />
        <span>Keine Angebote gefunden. Versuchen Sie eine neue Suche.</span>
      </StatusWrapper>
    );
  }

  return <></>;
};

SearchStatus.propTypes = {
  appIsLoading: PropTypes.bool,
  totalResults: PropTypes.number,
};

SearchStatus.defaultProps = {
  appIsLoading: true,
  totalResults: 0,
};

const mapStateToProps = createStructuredSelector({
  appIsLoading: makeSelectAppIsLoading(),
  totalResults: makeSelectTotalResults(),
});

const withConnect = connect(mapStateToProps);

export default compose(withConnect, memo)(SearchStatus);
