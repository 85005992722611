import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { makeSelectFilterActive, makeSelectTimeFilterContent } from '../selectors';
import { setTimeFilterDataArray, setTimeFilterId } from '../actions';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import initialStateConstants from '../initialStateConstants';

export const TimePreFilter = ({ timeItems, onSetTimeFilterId, onSetTimeFilterDataArray }) => {
  const appHistory = useHistory();
  const { search } = useLocation();
  const [queryParams, setQueryParams] = useState(queryString.parse(search)?.time ?? '');

  /**
   * @implements useEffect()
   *
   * Checks if filter should be run once (new) filterable items
   * become available.
   */
  useEffect(() => {
    if (queryParams.length > 0) {
      preFilter(queryParams);
    }
  }, [timeItems]);

  /**
   * @implements useEffect()
   *
   * Checks if a query parameter for this filter has changed and
   * kicks-off updates.
   */
  useEffect(() => {
    let newQueryString = queryString.parse(search)?.time ?? '';
    if (newQueryString === queryParams) {
      return null;
    }
    if (appHistory.action === 'POP') {
      preFilter(newQueryString);
    }
    setQueryParams(newQueryString);
  }, [search]);

  const preFilter = (updatedQueryString) => {
    if (timeItems.length === 0) {
      return null;
    }
    let filterItems = [];
    if (updatedQueryString.length > 0) {
      filterItems = updatedQueryString.split('_');
    }
    if (filterItems.length > 0) {
      onSetTimeFilterId(filterItems);
      const timeStrings = filterItems.map((currentItem) => {
        const result = timeItems.find((item) => Object.keys(item)[0] === currentItem);
        return result[Object.keys(result)].name;
      });
      onSetTimeFilterDataArray(timeStrings);
    } else {
      resetFilter();
    }
  };

  const resetFilter = () => {
    onSetTimeFilterId(initialStateConstants.timeFilterId);
    onSetTimeFilterDataArray(initialStateConstants.timeFilterDataArray);
  };

  return <></>;
};

TimePreFilter.propTypes = {
  timeItems: PropTypes.array,
  onSetTimeFilterId: PropTypes.func,
  onSetTimeFilterDataArray: PropTypes.func,
  filterActive: PropTypes.number,
};

TimePreFilter.defaultProps = {
  timeItems: [],
  onSetTimeFilterId: () => {},
  onSetTimeFilterDataArray: () => {},
  filterActive: 0,
};

const mapStateToProps = createStructuredSelector({
  timeItems: makeSelectTimeFilterContent(),
  filterActive: makeSelectFilterActive(),
});

export function mapDispatchToProps(dispatch) {
  return {
    onSetTimeFilterId: (value) => dispatch(setTimeFilterId(value)),
    onSetTimeFilterDataArray: (value) => dispatch(setTimeFilterDataArray(value)),
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect, memo)(TimePreFilter);
