import styled from 'styled-components';
import Colors from '../Colors';
import { APP_MAX_MOBILE_WIDTH, APP_MAX_TABLET_WIDTH, MOBILE_PADDING_LR, TABLET_PADDING_LR } from '../constants';

export const ResultCount = styled.span`
  display: block;
  max-width: 100%;
  margin: 18px ${MOBILE_PADDING_LR};

  @media (min-width: ${APP_MAX_MOBILE_WIDTH}px) {
    margin: 18px ${TABLET_PADDING_LR};
  }

  @media (min-width: ${APP_MAX_TABLET_WIDTH}px) {
    margin: 18px 0 40px;
    padding-left: 8px;
    color: ${Colors.greyDark};
  }
`;

export default ResultCount;
